<template>
  <div
    class="bg-ems-gray1000 min-h-100vh detail-ident-evt relative"
    tabindex="0"
  >
    <cds-loading
      v-if="isLoading"
      class="spin-custom mt-0 pt-50px !items-start"
    />
    <div
      class="flex justify-between items-end gap-[16px] bg-ems-gray800 pl-[18px] pr-[30px] py-[18px] border-b-1 border-solid border-ems-tag1 w-full flex-wrap"
    >
      <div class="flex items-center gap-20px max-w-1/2">
        <div class="hover:bg-ems-gray700 p-2 cursor-pointer" @click="onBack">
          <img
            src="/assets/ems/left.png"
            alt="left"
            class="block mx-auto"
            width="24"
            height="24"
          />
        </div>
        <span
          class="font-semibold text-white text-center leading-[30px] text-nowrap"
          >{{ t('identification-event.info') }}:</span
        >
        <div
          class="flex items-center gap-[16px] text-ems-white font-semibold text-center"
        >
          <div v-if="!isActivePrev" class="group">
            <img
              src="@/static/img/icon/PrevIcon.svg"
              alt=""
              class="cursor-pointer block group-hover:hidden"
              @click="onNavigate(NAVIGATION_TYPE.PREV)"
            />
            <img
              src="@/static/img/icon/PrevActiveIcon.svg"
              alt=""
              class="cursor-pointer hidden group-hover:block"
              @click="onNavigate(NAVIGATION_TYPE.PREV)"
            />
          </div>
          <div v-else>
            <img
              src="@/static/img/icon/PrevActiveIcon.svg"
              alt=""
              class="cursor-pointer"
              @click="onNavigate(NAVIGATION_TYPE.PREV)"
            />
          </div>
          <span
            :title="data.name ? data.name : data.ioId"
            class="text-ems-main2 text-16px font-semibold max-w-full truncate leading-[30px]"
          >
            <span
              class="cursor-pointer"
              @click="copyContent(data.name ? data.name : data.ioId)"
              >{{ getNameEvt }}</span
            >
          </span>
          <div v-if="!isActiveNext" class="group">
            <img
              src="@/static/img/icon/NextIcon.svg"
              alt=""
              class="cursor-pointer group-hover:hidden"
              @click="onNavigate(NAVIGATION_TYPE.NEXT)"
            />
            <img
              src="@/static/img/icon/NextActiveIcon.svg"
              alt=""
              class="cursor-pointer hidden group-hover:block"
              @click="onNavigate(NAVIGATION_TYPE.NEXT)"
            />
          </div>
          <div v-else>
            <img
              src="@/static/img/icon/NextActiveIcon.svg"
              alt=""
              class="cursor-pointer"
              @click="onNavigate(NAVIGATION_TYPE.NEXT)"
            />
          </div>
        </div>
      </div>
      <div class="flex gap-[16px]">
        <ClipButton
          v-if="data.tracing"
          class="flex items-center gap-[8px]"
          :type="'primary'"
          :disabled="isLoading"
          @click="viewTracingObject"
        >
          <img
            src="@/static/img/icon/camera-trace.png"
            alt="Icon camera"
            width="22"
            height="22"
          />
          <span class="text-nowrap">{{
            t('identification-event.trace-info')
          }}</span>
        </ClipButton>
        <ClipButton
          v-if="checkHasPermission(perms, OBJECT_PERM.EDIT)"
          class="flex items-center gap-[6px]"
          :type="'primary'"
          :disabled="isLoading"
          @click="onUpdateInfoIO"
        >
          <cds-feather-icons type="plus" size="18" />
          <span class="text-nowrap">{{
            t('identification-event.update-info')
          }}</span>
        </ClipButton>

        <ClipButton
          v-if="checkHasPermission(perms, EVENT_IDENTIFICATION_PERM.DELETE)"
          class="flex items-center gap-[6px] !px-[20px]"
          :type="'primary'"
          :disabled="isLoading"
          @click="onDelete"
        >
          <cds-feather-icons type="trash-2" size="18" />
          <span class="text-nowrap">{{ t('common.delete') }}</span>
        </ClipButton>
        <ClipButton
          class="flex items-center !px-5 gap-[6px]"
          :type="'secondary'"
          @click="onClose"
        >
          <cds-feather-icons type="x" size="20" />
          <span>{{ t('common.close') }}</span>
        </ClipButton>
      </div>
    </div>
    <div class="p-16px grid grid-cols-10 gap-16px">
      <div class="2xl:col-span-7 col-span-6 pb-16px">
        <div class="relative bg-ems-gray800 pt-[18px] px-[20px] pb-[24px]">
          <div
            class="text-ems-gray200 text-18px mb-24px font-semibold uppercase"
          >
            {{ t('identification-event.image') }}
          </div>
          <div
            class="w-full h-418px relative grid grid-cols-12 gap-15px image-event-custom"
          >
            <Image.PreviewGroup>
              <div class="w-full col-span-9 row-span-1">
                <div class="w-full h-418px relative group" v-if="data.imageUrl">
                  <Image
                    :src="data.imageUrl"
                    :alt="data.imageUrl"
                    class="block w-full !h-full rounded-8px"
                  >
                    <template #previewMask>
                      <cds-feather-icons
                        class="cursor-pointer"
                        color="#FFFFFF"
                        type="eye"
                        size="16"
                      />
                    </template>
                  </Image>
                </div>
                <div
                  v-else
                  class="bg-ems-gray700 h-418px rounded-8px flex flex-col justify-center"
                >
                  <img
                    src="../../../static/img/icon/ImageIcon.svg"
                    alt="Icon Upload"
                    class="block mx-auto"
                  />
                  <div class="text-center text-ems-gray300">
                    {{ t('object_information.no_image') }}
                  </div>
                </div>
              </div>
              <div class="col-span-3 h-418px grid grid-rows-2 gap-20px">
                <div class="relative group" v-if="data.imageCropUrl">
                  <Image
                    :src="data.imageCropUrl"
                    :alt="data.imageCropUrl"
                    class="block w-full !h-full rounded-8px object-cover"
                  >
                    <template #previewMask>
                      <cds-feather-icons
                        class="cursor-pointer"
                        color="#FFFFFF"
                        type="eye"
                        size="16"
                      />
                    </template>
                  </Image>
                </div>
                <div class="relative group" v-if="data.imageLicensePlateUrl">
                  <Image
                    :src="data.imageLicensePlateUrl"
                    :alt="data.imageLicensePlateUrl"
                    class="block w-full !h-full rounded-8px"
                  >
                    <template #previewMask>
                      <cds-feather-icons
                        class="cursor-pointer"
                        color="#FFFFFF"
                        type="eye"
                        size="16"
                      />
                    </template>
                  </Image>
                </div>
              </div>
            </Image.PreviewGroup>
          </div>
        </div>
        <div class="mt-[16px] bg-ems-gray800 p-[20px]">
          <span
            class="uppercase text-base font-semibold text-ems-gray200 mb-17px block"
            >{{ t('identification-event.location') }}</span
          >
          <GoongLineMap
            v-if="!loadingMap"
            ref="mapCommon"
            :markers="markers"
            :center="center"
          />
        </div>
      </div>
      <div class="2xl:col-span-3 col-span-4" v-if="!isLoading">
        <div class="relative bg-ems-gray800 pt-[18px] px-[20px] pb-[24px]">
          <div class="text-ems-gray200 text-lg mb-10px font-semibold uppercase">
            {{ t('identification-event.profile-info') }}
          </div>
          <div class="grid grid-cols-2 gap-x-20px gap-y-10px mb-[20px]">
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.object_type')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ t(getText(data.type, OBJECT_TYPE_TEXT_VALUE)) }}</span
              >
            </div>
            <div
              v-if="data.type === TYPE_TEXT.HUMAN"
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.object_name')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-main4 hover:underline cursor-pointer"
                @click="clickName"
                >{{ nameHuman }}</span
              >
            </div>
            <div
              v-else
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.license_plate')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-main4 hover:underline cursor-pointer"
                @click="clickName"
                >{{ nameVehicle }}</span
              >
            </div>
            <div
              v-if="data.type === TYPE_TEXT.VEHICLE"
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between col-span-2"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.vehicle_owner')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ data.detail ? data.detail.ownerVehicle : '' }}</span
              >
            </div>
          </div>
          <div class="text-ems-gray200 text-lg mb-10px font-semibold uppercase">
            {{ t('identification-event.ident_info') }}
          </div>
          <div class="grid grid-cols-2 gap-x-20px gap-y-10px mb-[10px]">
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.time-ident')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ formatDate(data.createdAt, 'HH:mm:ss - DD/MM/YYYY') }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.status')
              }}</span>
              <span
                v-if="data.status === EVENT_STATUS_VALUE.CHECKIN"
                class="px-[10px] py-[2px] inline-block border-[1px] w-[fit-content] border-solid rounded-4px"
                :class="'text-ems-boTro500 border-ems-boTro500'"
                >{{ t('identification-event.checkin') }}</span
              >
              <span
                v-if="data.status === EVENT_STATUS_VALUE.CHECKOUT"
                class="px-[10px] py-[2px] inline-block border-[1px] w-[fit-content] border-solid rounded-4px"
                :class="'text-ems-boTro4_600 border-ems-boTro4_600'"
                >{{ t('identification-event.checkout') }}</span
              >
              <span
                v-if="data.status === EVENT_STATUS_VALUE.APPEAR"
                class="px-[10px] py-[2px] inline-block border-[1px] w-[fit-content] border-solid rounded-4px"
                :class="'text-ems-boTro2_600 border-ems-boTro2_600'"
                >{{ t('identification-event.appear') }}</span
              >
              <span
                v-if="data.status === EVENT_STATUS_VALUE.INTRUSION"
                class="px-[10px] py-[2px] inline-block border-[1px] w-[fit-content] border-solid rounded-4px"
                :class="'text-ems-main1 border-ems-main1'"
                >{{ t('identification-event.intrusion') }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between col-span-2"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.location')
              }}</span>
              <a
                class="block mt-2 min-h-[25px] text-base text-ems-gray300 hover:text-ems-main2 hover:underline cursor-pointer"
                :href="`/trace-management/identification-event?searchCamera=${data.cameraId}`"
                target="_blank"
                >{{ data.cameraName + ' - ' + data.location }}</a
              >
            </div>
          </div>
          <div
            class="grid grid-cols-2 gap-x-20px gap-y-10px"
            v-if="data.type === TYPE_TEXT.HUMAN"
          >
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.age')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ data.humanInfo ? data.humanInfo.face.age : '' }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.gender')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.humanInfo && data.humanInfo.face.gender
                    ? t(GENDER[data.humanInfo.face.gender])
                    : ''
                }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.chungToc')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.humanInfo && data.humanInfo.face.race
                    ? t(RACE[data.humanInfo.face.race])
                    : ''
                }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.kieuToc')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.humanInfo && data.humanInfo.person.hair
                    ? t(HAIR[data.humanInfo.person.hair])
                    : ''
                }}</span
              >
            </div>

            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.kieuAo')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ data.humanInfo ? kieuAo : '' }}</span
              >
            </div>

            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.kieuQuan')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ data.humanInfo ? kieuQuan : '' }}</span
              >
            </div>

            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.kieuTui')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.humanInfo && data.humanInfo.bag
                    ? t(BAG[data.humanInfo.bag])
                    : ''
                }}</span
              >
            </div>
            <div class="flex gap-[20px] self-end">
              <div class="flex gap-[8px] items-center">
                <span class="text-sm text-ems-gray500">
                  {{ t('identification-event.mu') }}
                </span>
                <a-checkbox
                  class="text-ems-gray500"
                  v-model:checked="mu"
                  disabled
                ></a-checkbox>
              </div>
              <div class="flex gap-[8px] items-center">
                <span class="text-sm text-ems-gray500">
                  {{ t('identification-event.kinhMat') }}
                </span>
                <a-checkbox
                  class="text-ems-gray500"
                  disabled
                  v-model:checked="kinhMat"
                ></a-checkbox>
              </div>
            </div>
          </div>
          <div
            v-if="data.type === TYPE_TEXT.VEHICLE"
            class="grid grid-cols-2 gap-x-20px gap-y-10px"
          >
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.vehicle_type')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ data.vehicleInfo ? getNameVehicleType : '' }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.vehicle_brand')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.vehicleInfo && data.vehicleInfo.vehicleBrand
                    ? vehicleBrand
                    : ''
                }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.color')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ vehicleColor }}</span
              >
            </div>
          </div>
          <div
            class="text-ems-gray200 text-18px mb-10px font-semibold mt-[20px] uppercase"
          >
            {{ t('object_information.group_belong') }}
          </div>
          <div class="col-span-2 flex flex-wrap gap-16px">
            <div
              v-if="listGroupDetail && listGroupDetail.length < 1"
              class="text-ems-main2"
            >
              {{ t('object_information.not_belong_any_group') }}
            </div>
            <div
              v-else
              v-for="item in listGroupDetail"
              :key="item"
              class="group tag bg-ems-main1 py-6px px-16px rounded-8px text-ems-white text-16px leading-25px relative cursor-pointer"
              :class="classGroupTag(item)"
              @click="viewGroup(item)"
            >
              {{
                item.name > 20 ? `${item.name.substring(0, 20)}...` : item.name
              }}
            </div>
          </div>
        </div>

        <!--        Hồ sơ ảnh tương đồng-->
        <div class="mt-16px p-16px bg-ems-gray800">
          <div
            class="text-ems-gray200 text-18px mb-24px font-semibold uppercase"
          >
            {{ t('identification-event.io-same-img') }}
          </div>
          <cds-input
            class="w-100 ant-input-sm"
            v-model:value="formState.name"
            :show-count="false"
            size="default"
            :placeholder="
              t('identity_object.form_label.search_by_info_placeholder')
            "
            @input="onChangeInput"
          />
          <p class="text-ems-gray200 text-18px my-3">
            {{ t('common.suggest') }}
          </p>
          <Empty
            v-if="listSimilar.length === 0"
            :description="t('common.no_data_similar')"
          />
          <div
            v-else
            class="flex flex-col gap-[20px] max-h-550px overflow-y-auto"
          >
            <div
              v-for="item in listSimilar"
              :key="item.id"
              class="flex gap-[20px] p-[20px] bg-ems-tag1 rounded-lg relative"
            >
              <img
                :src="
                  !item.imagesAvatar
                    ? require('@/static/img/no-img.png')
                    : item.imagesAvatar
                "
                :alt="item.imagesAvatar"
                width="120"
                height="120"
                class="rounded-lg w-[120px] h-[120px] flex-shrink-0 object-cover"
              />
              <div class="flex flex-col gap-12px w-full truncate">
                <span
                  class="text-lg text-ems-gray100 font-semi-bold truncate"
                  >{{ item.name ? item.name : item.id }}</span
                >
                <ul
                  class="list-disc list-inside text-12px text-ems-gray500 font-semibold pl-5px similar-profile-ul w-full"
                >
                  <template v-if="item.type === OBJECT_TYPE.HUMAN">
                    <li class="leading-19px mb-5px truncate">
                      {{ t('event_verify.date_of_birth') }}:
                      {{
                        item.human
                          ? formatDate(item.human.date_of_birth, 'DD/MM/YYYY')
                          : ''
                      }}
                    </li>
                    <li class="leading-19px mb-5px truncate">
                      {{ t('event_verify.address') }}:
                      {{ item.human ? item.human.address : '' }}
                    </li>
                    <li class="leading-19px mb-5px truncate">
                      {{ t('event_verify.phone') }}:
                      {{ item.human ? item.human.phone : '' }}
                    </li>
                  </template>
                  <template v-if="item.type === OBJECT_TYPE.VEHICLE">
                    <li class="leading-19px mb-5px truncate">
                      {{ t('event_verify.category') }}:
                      {{
                        item.vehicle
                          ? getNameVehicleType(item.vehicle.vehicle_type)
                          : ''
                      }}
                    </li>
                    <li class="leading-19px mb-5px truncate">
                      {{ t('event_verify.brand') }}:
                      {{ item.vehicle ? item.vehicle.brand_id : '' }}
                    </li>
                    <li class="leading-19px mb-5px truncate">
                      {{ t('event_verify.color') }}:
                      {{
                        item.vehicle
                          ? vehicleColor(item.vehicle.vehicle_color)
                          : ''
                      }}
                    </li>
                  </template>
                </ul>
                <div
                  class="w-[fit-content] self-end mt-5px px-[16px] py-[3px] text-[12px] text-ems-gray300 border-1px border-solid border-ems-gray300 block bottom-[13px] right-[16px] rounded-full cursor-pointer"
                  @click="editDetailIO(item)"
                >
                  {{ t('identification-event.merge_profile') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
</template>
<script setup>
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  FORM_MODE,
  OBJECT_TYPE,
  OBJECT_TYPE_TEXT_VALUE,
  TYPE,
} from '@/util/common-constant';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { formatDate, getText } from '@/util/common-utils';
import GoongLineMap from '@/components/leaflet-map/index.vue';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import Empty from '@/components/empty/index.vue';
import {
  BAG,
  COLOR,
  GENDER,
  HAIR,
  RACE,
  STYLE,
  WARNING_LEVEL,
} from '../../../config/Constant';
import { Image } from 'ant-design-vue';
import {
  EVENT_STATUS_VALUE,
  NAVIGATION_TYPE,
  TYPE_TEXT,
  TAB_KEY,
  OBJECT_PERM,
  EVENT_IDENTIFICATION_PERM,
} from '../../../util/common-constant';
import { debounce } from 'lodash';
import { copyContent, checkHasPermission } from '../../../util/common-utils';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { state, dispatch } = useStore();
const classGroupTag = (item) => {
  return {
    'bg-ems-main1': item.warningLevel === WARNING_LEVEL[0].id,
    'bg-ems-boTro2_600': item.warningLevel === WARNING_LEVEL[1].id,
    'bg-ems-boTro4_800': item.warningLevel === WARNING_LEVEL[2].id,
    'bg-ems-gray500': item.warningLevel === WARNING_LEVEL[3].id,
  };
};
const isIntrusion = ref(false);
const perms = computed(() => state.auth.userInfo.permissions);
const data = computed(() => state.identificationEvent.identEvt);
const listSimilar = computed(() => state.identificationEvent.listSimilar);
const listGroup = computed(() => state.ioGroup.listGroup);
const listGroupDetail = computed(() => {
  let list = [];
  if (data.value.ioGroups && listGroup.value) {
    data.value.ioGroups.forEach((item) => {
      const index = listGroup.value.findIndex((group) => group.id === item);
      if (index !== -1) {
        list.push(listGroup.value[index]);
      }
    });
  }
  return list;
});
const kieuAo = computed(() => {
  let arr = [];
  if (data.value && data.value.humanInfo && data.value.humanInfo.person) {
    arr = data.value.humanInfo.person.upper.style
      ? [t(STYLE[data.value.humanInfo.person.upper.style])]
      : [];
    if (data.value.humanInfo.person.upper.color) {
      data.value.humanInfo.person.upper.color.forEach((e) => {
        arr.push(t(COLOR[e]));
      });
    }
  }
  return arr.join(', ');
});
const kieuQuan = computed(() => {
  let arr = [];
  if (data.value && data.value.humanInfo && data.value.humanInfo.person) {
    arr = data.value.humanInfo.person.lower.style
      ? [t(STYLE[data.value.humanInfo.person.lower.style])]
      : [];
    if (data.value.humanInfo.person.lower.color) {
      data.value.humanInfo.person.lower.color.forEach((e) => {
        arr.push(t(COLOR[e]));
      });
    }
  }
  return arr.join(', ');
});
const formState = reactive({
  name: '',
});
const onChangeInput = debounce(() => {
  dispatch('identificationEvent/getListSimilar', {
    keyword: formState.name,
    type: OBJECT_TYPE[data.value.type],
  });
}, 300);
const mu = computed(
  () => data.value.humanInfo && data.value.humanInfo.person.hat
);
const kinhMat = computed(
  () => data.value.humanInfo && data.value.humanInfo.person.glasses
);
const color = computed(() => state.listSelect.color);
const vehicleColor = computed(() => {
  const index = color.value.findIndex(
    (item) => item.value === data.value.vehicleInfo.vehicleColor
  );
  if (index !== -1) {
    return color.value[index].name;
  }
  return '';
});
const vehicleType = computed(() => state.listSelect.vehicleType);
const getNameVehicleType = computed(() => {
  const index = vehicleType.value.findIndex(
    (item) => item.value === data.value.vehicleInfo.vehicleName
  );
  if (index !== -1) {
    return vehicleType.value[index].name;
  }
  return '';
});

const vehicleBrand = computed(() => {
  if (data.value.vehicleInfo.vehicleBrand !== TYPE.UNKNOWN) {
    return data.value.vehicleInfo.vehicleBrand;
  }
  return '';
});

const markers = ref([]);
const mapCommon = ref(null);
const center = ref({ lat: 21.051448321171982, lng: 105.80020101183814 });
const isActivePrev = ref(false);
const isActiveNext = ref(false);
const loadingMap = ref(true);
const isInOut = ref(false);
onMounted(async () => {
  isLoading.value = true;
  isIntrusion.value = route.query.isIntrusion ? true : false;
  isInOut.value = route.query.isInOut ? true : false;
  const getDetailSuccess = await dispatch('identificationEvent/getDetail', {
    id: route.query.id,
  });
  if (getDetailSuccess) {
    getMarker();
  }
  if (isIntrusion.value) {
    dispatch('setTitleHeader', t('identification-event.title-in-out'));
  } else if (isInOut.value) {
    dispatch('setTitleHeader', t('identification-event.title-intrusion'));
  } else {
    dispatch('setTitleHeader', t('identification-event.title-view'));
  }
  let promises = [
    dispatch('ioGroup/getAllGroup'),
    dispatch('identificationEvent/getListSimilar', {
      id: data.value.ioId,
      keyword: null,
      type: OBJECT_TYPE[data.value.type],
    }),
    dispatch('listSelect/getAllColor'),
    dispatch('listSelect/getAllVehicleType'),
  ];
  await Promise.all(promises);
  isLoading.value = false;
  loadingMap.value = false;
  window.addEventListener('keyup', onKeyUp);
});
const onKeyUp = (e) => {
  if (e.keyCode === 37) {
    onNavigate(NAVIGATION_TYPE.PREV);
  } else if (e.keyCode === 39) {
    onNavigate(NAVIGATION_TYPE.NEXT);
  }
};
const isLoading = ref(false);
const clickName = () => {
  router.push({
    name: '/io-management/object-management/edit',
    query: {
      id: data.value.ioId,
      type: OBJECT_TYPE[data.value.type],
      mode: checkHasPermission(perms.value, OBJECT_PERM.EDIT)
        ? FORM_MODE.UPDATE
        : FORM_MODE.VIEW,
      tab: 'history',
    },
  });
};

const getNameEvt = computed(() => {
  let name = data.value.name ? data.value.name : data.value.ioId;
  return name.length > 25 ? `${name.substring(0, 25)}...` : name;
});

const nameHuman = computed(() => {
  let name = data.value.name ? data.value.name : data.value.ioId;
  return name.length > 20 ? `${name.substring(0, 20)}...` : name;
});

const nameVehicle = computed(() => {
  let name =
    data.value.detail && data.value.detail.licensePlate
      ? data.value.detail.licensePlate
      : data.value.ioId;
  return name.length > 20 ? `${name.substring(0, 20)}...` : name;
});

const getMarker = () => {
  let item = {
    lat: data.value.latitude,
    lng: data.value.longitude,
    type: 'current',
    infoWindow: `
          <div class="content-wrapper p-16px">
            <img
              src="${
                data.value.imageCropUrl
                  ? data.value.imageCropUrl
                  : require('@/static/img/no-img.png')
              }"
              width="120"
              height="120"
              class="rounded-lg w-[120px] h-[120px] flex-shrink-0 object-cover"
            />
            <div class="flex flex-col justify-center gap-[12px]">
              <span class="text-base text-ems-white font-semibold whitespace-nowrap">${
                data.value.name ? data.value.name : data.value.ioId
              }</span>
              <ul class="list-disc ml-20px flex flex-col gap-1 w-[300px]">
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.time-appear'
                )}  ${formatDate(
      data.value.createdAt,
      'DD/MM/YYYY - HH:mm:ss'
    )}  </li>
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.camera'
                )}   ${data.value.cameraName} </li>
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.vi-tri'
                )}   ${data.value.location} </li>
              </ul>
            </div>
          </div>
    `,
  };
  center.value.lat = data.value.latitude;
  center.value.lng = data.value.longitude;
  markers.value = [item];
};
const setMarker = () => {
  mapCommon.value.setMarker(markers.value);
  mapCommon.value.moveToLocation(data.value.latitude, data.value.longitude);
};
onUnmounted(() => {
  dispatch('identificationEvent/resetForm');
  window.removeEventListener('keyup', onKeyUp);
});
const viewGroup = (item) => {
  router.push({
    path: '/io-management/group-management',
    query: { idGroup: item.id },
  });
};

const editDetailIO = (item) => {
  const routeLink = router.resolve({
    name: '/io-management/object-management/edit',
    query: {
      id: item.id,
      type: item.type,
      mode: FORM_MODE.UPDATE,
      profileEventId: data.value.ioId,
      profileEventType: OBJECT_TYPE[data.value.type],
    },
  });
  window.open(routeLink.href, '_blank');
};
const onUpdateInfoIO = () => {
  router.push({
    name: '/io-management/object-management/edit',
    query: {
      id: data.value.ioId,
      type: OBJECT_TYPE[data.value.type],
      mode: checkHasPermission(perms.value, OBJECT_PERM.EDIT)
        ? FORM_MODE.UPDATE
        : FORM_MODE.VIEW,
    },
  });
};
const showConfirmCustom = ref(null);
const onDelete = async () => {
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    dispatch('identificationEvent/delete', [{ id: route.query.id }]).then(
      async () => {
        let response = await navigateEvent(NAVIGATION_TYPE.PREV);
        if (!response) {
          onClose();
        }
      }
    );
  }
};

const viewTracingObject = () => {
  router.push({
    path: '/trace-management/object-tracing/detail',
    query: { id: data.value.ioId, tab: TAB_KEY.INFO },
  });
};
const onClose = () => {
  if (isIntrusion.value) {
    router.push({
      path: '/trace-management/intrusion-event',
      query: {
        groupCameraId: route.query.groupCameraId
          ? route.query.groupCameraId
          : null,
        cameraIds: route.query.cameraIds ? route.query.cameraIds : null,
      },
    });
  } else if (isInOut.value) {
    router.push({
      path: '/trace-management/in-out-event',
      query: {
        groupCameraId: route.query.groupCameraId
          ? route.query.groupCameraId
          : null,
        cameraIds: route.query.cameraIds ? route.query.cameraIds : null,
        chartBy: route.query.chartBy ? route.query.chartBy : null,
        dateFrom: route.query.dateFrom ? route.query.dateFrom : null,
        dateTo: route.query.dateTo ? route.query.dateTo : null,
        keyword: route.query.keyword ? route.query.keyword : null,
        directionType: route.query.directionType
          ? route.query.directionType
          : null,
        type: route.query.type ? route.query.type : null,
      },
    });
  } else
    router.push({
      path: '/trace-management/identification-event',
      query: {
        searchName: route.query.searchName ? route.query.searchName : null,
        searchType: route.query.searchType ? route.query.searchType : null,
        searchGroupId: route.query.searchGroupId
          ? route.query.searchGroupId
          : null,
        searchStatus: route.query.searchStatus
          ? route.query.searchStatus
          : null,
        searchCamera: route.query.searchCamera
          ? route.query.searchCamera
          : null,
        searchCameraGroup: route.query.searchCameraGroup
          ? route.query.searchCameraGroup
          : null,
        searchDateFrom: route.query.searchDateFrom
          ? route.query.searchDateFrom
          : null,
        searchDateTo: route.query.searchDateTo
          ? route.query.searchDateTo
          : null,
        searchImage: route.query.searchImage ? route.query.searchImage : null,
        searchIsWarningConfig: route.query.searchIsWarningConfig
          ? route.query.searchIsWarningConfig
          : null,
        searchAge: route.query.searchAge ? route.query.searchAge : null,
        searchGender: route.query.searchGender
          ? route.query.searchGender
          : null,
        searchRace: route.query.searchRace ? route.query.searchRace : null,
        searchVehicleBrand: route.query.searchVehicleBrand
          ? route.query.searchVehicleBrand
          : null,
        searchVehicleName: route.query.searchVehicleName
          ? route.query.searchVehicleName
          : null,
        searchVehicleColor: route.query.searchVehicleColor
          ? route.query.searchVehicleColor
          : null,
        page: route.query.page ? route.query.page : null,
        size: route.query.size ? route.query.size : null,
      },
    });
};
const onBack = () => {
  if (window.history.state.back) {
    history.back();
    if (
      window.history.state.back.includes(
        '/trace-management/identification-event/detail'
      )
    )
      setTimeout(async () => {
        isLoading.value = true;
        const getDetailSuccess = await dispatch(
          'identificationEvent/getDetail',
          {
            id: route.query.id,
          }
        );
        if (getDetailSuccess) {
          getMarker();
          setMarker();
        }
        isLoading.value = false;
      }, 100);
  } else {
    router.push({
      path: '/trace-management/identification-event',
      query: {
        searchName: route.query.searchName ? route.query.searchName : null,
        searchType: route.query.searchType ? route.query.searchType : null,
        searchGroupId: route.query.searchGroupId
          ? route.query.searchGroupId
          : null,
        searchStatus: route.query.searchStatus
          ? route.query.searchStatus
          : null,
        searchCamera: route.query.searchCamera
          ? route.query.searchCamera
          : null,
        searchCameraGroup: route.query.searchCameraGroup
          ? route.query.searchCameraGroup
          : null,
        searchDateFrom: route.query.searchDateFrom
          ? route.query.searchDateFrom
          : null,
        searchDateTo: route.query.searchDateTo
          ? route.query.searchDateTo
          : null,
        searchImage: route.query.searchImage ? route.query.searchImage : null,
        searchIsWarningConfig: route.query.searchIsWarningConfig
          ? route.query.searchIsWarningConfig
          : null,
        searchAge: route.query.searchAge ? route.query.searchAge : null,
        searchGender: route.query.searchGender
          ? route.query.searchGender
          : null,
        searchRace: route.query.searchRace ? route.query.searchRace : null,
        searchVehicleBrand: route.query.searchVehicleBrand
          ? route.query.searchVehicleBrand
          : null,
        searchVehicleName: route.query.searchVehicleName
          ? route.query.searchVehicleName
          : null,
        searchVehicleColor: route.query.searchVehicleColor
          ? route.query.searchVehicleColor
          : null,
        page: route.query.page ? route.query.page : null,
        size: route.query.size ? route.query.size : null,
      },
    });
  }
};
const onNavigate = async (navigationType) => {
  isLoading.value = true;
  if (navigationType === NAVIGATION_TYPE.PREV) {
    isActivePrev.value = true;
    isActiveNext.value = false;
  } else {
    isActivePrev.value = false;
    isActiveNext.value = true;
  }
  let response = await navigateEvent(navigationType);
  if (response) {
    await router.push({
      query: {
        ...route.query,
        id: data.value.id,
      },
    });
    getMarker();
    setMarker();
  }
  isLoading.value = false;
};
const navigateEvent = async (navigationType) => {
  let response;
  if (isIntrusion.value) {
    response = await dispatch('identificationEvent/intrusionNavigation', {
      groupCameraId: route.query.groupCameraId,
      cameraIds: route.query.cameraIds,
      dateFrom: route.query.dateFrom,
      dateTo: route.query.dateTo,
      navigationType: navigationType,
      currentCreatedAt: data.value.createdAt,
    });
  } else if (isInOut.value) {
    response = await dispatch('identificationEvent/inOutNavigation', {
      type: route.query.type,
      dateFrom: route.query.dateFrom,
      dateTo: route.query.dateTo,
      groupCameraId: route.query.groupCameraId,
      cameraIds: route.query.cameraIds,
      navigationType: navigationType,
      currentCreatedAt: data.value.createdAt,
    });
  } else {
    response = await dispatch('identificationEvent/changeEvent', {
      name: route.query.searchName,
      type: route.query.searchType,
      groupId: route.query.searchGroupId,
      status: route.query.searchStatus,
      image: route.query.searchImage,
      cameraId: route.query.searchCamera,
      groupCameraIds: route.query.searchCameraGroup,
      cameraIds: route.query.searchCamera,
      isWarningConfig: route.query.searchIsWarningConfig,
      dateFrom: route.query.searchDateFrom
        ? new Date(route.query.searchDateFrom)
        : null,
      dateTo: route.query.searchDateTo
        ? new Date(route.query.searchDateTo)
        : null,
      searchAge: route.query.searchAge ? route.query.searchAge : null,
      searchGender: route.query.searchGender ? route.query.searchGender : null,
      searchRace: route.query.searchRace ? route.query.searchRace : null,
      searchVehicleBrand: route.query.searchVehicleBrand
        ? route.query.searchVehicleBrand
        : null,
      searchVehicleName: route.query.searchVehicleName
        ? route.query.searchVehicleName
        : null,
      searchVehicleColor: route.query.searchVehicleColor
        ? route.query.searchVehicleColor
        : null,
      navigationType: navigationType,
      currentCreatedAt: data.value.createdAt,
      eventId: route.query.eventId ? route.query.eventId : null,
      objectId: route.query.objectId ? route.query.objectId : null,
    });
  }
  return response;
};
</script>
<style lang="scss">
.detail-ident-evt .tag:hover {
  transition: 0.2s;
  transform: scale(1.1);
}
.image-event-custom .ant-image {
  height: 100%;
  width: 100%;
}
.image-event-custom .ant-image-mask {
  border-radius: 8px;
}
img.ant-image-preview-img {
  display: inline-block;
}
.ant-checkbox-wrapper span {
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.similar-profile-ul li::marker {
  font-size: 0.7em;
}
</style>
