<template>
  <div id="heatmap"></div>
</template>

<script setup>
import L from 'leaflet';
import 'leaflet.heat';
import { onMounted } from 'vue';
import { MARKER_TYPE,LAYER_MAP } from '@/util/common-constant.js';
const props = defineProps({
  center: {
    type: Object,
    default: () => {
      return { lat: 21.051528423347538, lng: 105.80015809649605 };
    },
  },
  markers: {
    type: Array,
    default: () => [],
  },
});
const iconType = {};
iconType[MARKER_TYPE.CAMERA_SELECTED] = {
  icon: require('@/static/img/map/camera.png'),
  classAnimate:
    'animate-ping top-0 l-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full bg-transparent border-none',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.CAMERA_DEFAULT] = {
  icon: require('@/static/img/map/camera-default.png'),
  classAnimate: '',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.DEFAULT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.DO] = {
  icon: require('@/static/img/map/do.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 l-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full bg-transparent border-none',
};
iconType[MARKER_TYPE.VANG] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.VANG_SELECTED] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 l-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro3_600 !opacity-25 rounded-full bg-transparent border-none',
};
iconType[MARKER_TYPE.CURRENT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-[-15px] left-[-15px] absolute inline-flex h-[60px] w-[60px]  bg-[#3A5EF6] !opacity-25 rounded-full bg-transparent border-none',
};
iconType[MARKER_TYPE.TRINH_SAT] = {
  icon: require('@/static/img/map/scout.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 l-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro4_600 !opacity-25 rounded-full bg-transparent border-none',
};
iconType[MARKER_TYPE.LOCATION] = {
  icon: require('@/static/img/map/location.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
onMounted(() => {
  const map = L.map('heatmap').setView([21.052, 105.800], 13);
  L.tileLayer(LAYER_MAP, {
    maxZoom: 16,
  }).addTo(map);

  // Dữ liệu heatmap
  const heatData = props.markers
    .filter((marker) => marker.weight > 0)
    .map((marker) => [marker.lat, marker.lng, marker.weight]);

  const heatLayer = L.heatLayer(heatData, {
    radius: 25,
    blur: 15,
    maxZoom: 16,
    gradient: {
      0.4: 'blue',
      0.6: 'lime',
      0.7: 'orange',
      1.0: 'red',
    },
  }).addTo(map);
  props.markers.forEach((marker) => {
    const icon = L.divIcon({
      html: `
        <div>
          <img src="${iconType[marker.type].icon}" class="${iconType[marker.type].class}" />
          <div class="${iconType[marker.type].classAnimate}"></div>
        </div>
      `,
      iconSize: [30, 30],
    });
    L.marker([marker.lat, marker.lng], { icon }).addTo(map);
  });
});

</script>

<style lang="scss">

#heatmap {
  height: 700px;
  .leaflet-marker-icon.leaflet-interactive{
    background:transparent;
    border: none;
  }
}

</style>
