<template>
  <div
    class="dashboard-intrusion p-24px flex justify-between bg-ems-gray800 border-b-1px border-ems-tag1"
  >
    <div class="flex 2xl:gap-[20px] gap-[10px]">
      <cds-select
        class="!w-[205px] h-[39px]"
        :placeholder="t('intrusion-event.area')"
        :options="groupCameras"
        v-model:value="formState.groupCameraId"
        :allow-clear="true"
        :label-prop="'name'"
        :value-prop="'id'"
        @change="changeGroupCamera"
      />
      <div class="relative h-[39px]">
        <cds-select
          class="!w-[205px] h-[39px]"
          :placeholder="t('object_information.camera')"
          :options="cameras"
          v-model:value="formState.cameraIds"
          :allow-clear="true"
          :label-prop="'name'"
          :value-prop="'id'"
          multiple
          :maxTagCount="1"
          :max-tag-text-length="10"
          :is-show-search="true"
          @search="onFilterCamera"
          @blur="onBlurCamera"
          @change="getChartData"
        >
        </cds-select>
        <cds-feather-icons
          v-if="formState.cameraIds.length === 0 && keyFilterCamera === ''"
          type="chevron-down"
          class="text-ems-gray400 absolute right-2 inline-flex items-center h-full h-[34px]"
        />
      </div>
      <cds-select
        class="!w-[205px] h-[39px]"
        :placeholder="t('intrusion-event.statistics_follow')"
        :options="statisticalOptions"
        v-model:value="formState.chartBy"
        :allow-clear="true"
        :is-show-search="true"
        @change="onChangeChartBy"
      />
      <DatePickerChart
        class="!w-[205px] h-[39px]"
        v-model:value="formState.dateFrom"
        :placeholder="t('intrusion-event.from')"
        :picker="typeDatePicker"
        :format="formatDatePicker"
        :disabled-date="disabledDateFrom"
        @pickDate="getChartData"
        :show-time="true"
      />
      <DatePickerChart
        class="!w-[205px] h-[39px]"
        v-model:value="formState.dateTo"
        :placeholder="t('intrusion-event.to')"
        :picker="typeDatePicker"
        :format="formatDatePicker"
        :disabled-date="disabledDateTo"
        :is-from-date="false"
        @pickDate="getChartData"
        :show-time="true"
      />
    </div>
    <div class="flex items-center gap-[20px]">
      <clip-button
        class="flex items-center text-sm text-ems-white text-nowrap h-[39px] !px-[30px]"
        :type="'secondary'"
        @click="resetData"
      >
        <cds-feather-icons
          type="rotate-cw"
          size="16"
          class="!text-ems-white mr-2"
        />
        {{ $t('common.reset') }}
      </clip-button>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-16px m-16px">
    <div class="relative bg-ems-gray800 text-ems-white">
      <div
        v-if="isLoadingChart"
        class="spin-custom flex justify-center items-center"
      >
        <cds-loading />
      </div>
      <div
        class="flex justify-between items-center border-b-1px border-ems-gray700 p-20px"
      >
        <div class="text-16px font-semibold leading-25px">
          {{ t('intrusion-event.statistics_gender') }}
        </div>
        <div class="flex items-center text-ems-gray400">
          <div class="w-122px">{{ t('intrusion-event.total') }}</div>
          <div class="text-ems-main2 text-28px font-semibold">
            {{ formatNumber(chartByGender.total) }}
          </div>
        </div>
      </div>
      <Chart
        ref="verticalChart"
        :option="genderChart"
        class-name="chart bg-evisa-gray50 rounded-lg pr-4"
      />
      <div
        class="flex gap-30px border-t-1px border-ems-gray700 pt-10px pb-20px pl-20px"
      >
        <div class="flex items-center gap-12px">
          <div class="w-12px h-12px rounded-full bg-ems-boTro3_300"></div>
          {{ t('intrusion-event.male') }}
        </div>
        <div class="flex items-center gap-12px">
          <div class="w-12px h-12px rounded-full bg-ems-main2"></div>
          {{ t('intrusion-event.female') }}
        </div>
        <div class="flex items-center gap-12px">
          <div class="w-12px h-12px rounded-full bg-ems-boTro4_600"></div>
          {{ t('intrusion-event.other') }}
        </div>
      </div>
    </div>
    <div class="relative bg-ems-gray800 text-ems-white">
      <div
        v-if="isLoadingChart"
        class="spin-custom flex justify-center items-center"
      >
        <cds-loading />
      </div>
      <div
        class="flex justify-between items-center border-b-1px border-ems-gray700 p-20px"
      >
        <div class="text-16px font-semibold leading-25px">
          {{ t('intrusion-event.statistics_general') }}
        </div>
        <div class="flex items-center text-ems-gray400">
          <div class="w-122px">{{ t('intrusion-event.total') }}</div>
          <div class="text-ems-main2 text-28px font-semibold">
            {{ formatNumber(chartByCamera.total) }}
          </div>
        </div>
      </div>
      <Chart
        ref="horizontalChart"
        :option="cameraChart"
        class-name="chart bg-evisa-gray50 rounded-lg pr-4"
        :height="'350px'"
      />
    </div>
  </div>
  <div class="grid grid-cols-1 m-16px 3xl:h-[calc(100%-624px)]">
    <IntrusionTable v-model:params="formState" :refresh="refresh" />
  </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ClipButton from '@/components/buttons/ClipButton.vue';
import Chart from '@/components/e-charts/index.vue';
import { useStore } from 'vuex';
import {
  formatDate,
  formatNumber,
  setDefaultDateForm,
} from '../../../../util/common-utils';
import dayjs from 'dayjs';
import DatePickerChart from '../../../../components/date-picker-chart/DatePickerChart.vue';
import IntrusionTable from './IntrusionTable.vue';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();
const { state, dispatch } = useStore();
const route = useRoute();
const router = useRouter();
const chartByGender = computed(() => state.identificationEvent.chartByGender);
const chartByCamera = computed(() => state.identificationEvent.chartByCamera);
const isLoadingChart = computed(() => state.identificationEvent.isLoadingChart);
const groupCameras = computed(() => state.tracingObject.listGroupCamera);
const cameras = computed(() => state.tracingObject.listCameraByGroup);
const formState = reactive({
  groupCameraId: null,
  cameraIds: [],
  chartBy: null,
  dateFrom: null,
  dateTo: null,
});
const genderChart = ref({
  legend: {
    show: false,
  },
  textStyle: {
    fontFamily: 'Saira',
    color: '#F7F0F7',
    fontSize: '14px',
  },
  tooltip: {},
  dataset: {
    source: [],
  },
  xAxis: {
    type: 'category',
    axisLine: {
      lineStyle: {
        type: 'dashed',
        color: '#2B2A3A',
      },
    },
    splitLine: {
      show: false,
    },
    axisTick: {
      alignWithLabel: true,
      inside: true,
    },
  },
  yAxis: {
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: '#2B2A3A',
      },
    },
    axisLabel: {
      textStyle: {
        color: '#F7F0F7',
      },
    },
    axisTick: {
      alignWithLabel: true,
      inside: true,
    },
  },
  series: [
    {
      type: 'bar',
      itemStyle: {
        color: '#F4E36B',
      },
      barMaxWidth: 24,
    },
    {
      type: 'bar',
      itemStyle: {
        color: '#5B5B9F',
      },
      barMaxWidth: 24,
    },
    {
      type: 'bar',
      itemStyle: {
        color: '#21A603',
      },
      barMaxWidth: 24,
    },
  ],
});
const cameraData = ref([]);
const totalCameraData = ref([]);
const columns = ref([]);
const refresh = ref(false);
const cameraChart = ref({
  legend: {
    show: true,
    textStyle: {
      color: '#F7F0F7',
    },
    bottom: 15,
    left: 20,
    itemGap: 15,
    icon: 'circle',
    type: 'scroll',
    pageTextStyle: {
      color: '#fff',
    },
    pageIconColor: '#fff',
  },
  textStyle: {
    fontFamily: 'Saira',
    color: '#F7F0F7',
    fontSize: '14px',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
    },
    valueFormatter: (value, id) =>
      Math.round(value * totalCameraData.value[id]) +
      ' - ' +
      Math.round(value * 1000) / 10 +
      '%',
  },
  dataZoom: [
    {
      type: 'slider',
      start: 0,
      end: 100,
      yAxisIndex: 0,
    },
  ],
  xAxis: {
    show: false,
    type: 'value',
    axisLine: {
      lineStyle: {
        type: 'dashed',
        color: '#2B2A3A',
      },
    },
    splitLine: {
      show: false,
    },
  },
  yAxis: {
    type: 'category',
    data: [],
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: '#2B2A3A',
      },
    },
    axisLabel: {
      textStyle: {
        color: '#F7F0F7',
      },
    },
    axisTick: {
      alignWithLabel: true,
      inside: true,
    },
  },
});
onMounted(async () => {
  formState.groupCameraId = route.query.groupCameraId
    ? route.query.groupCameraId
    : null;
  formState.cameraIds = route.query.cameraIds
    ? route.query.cameraIds.split(',')
    : [];
  formState.chartBy = route.query.chartBy
    ? route.query.chartBy
    : STATISTICAL_OBJECT.MONTH;
  formState.dateFrom = route.query.dateFrom
    ? new Date(route.query.dateFrom)
    : new Date(new Date().getFullYear(), 0) || null;
  formState.dateTo = route.query.dateTo
    ? new Date(route.query.dateTo)
    : new Date() || null;
  const promise = [
    dispatch('tracingObject/getListGroupCamera'),
    dispatch('ioGroup/getAllGroup'),
  ];
  await Promise.all(promise);
  const response = await dispatch('tracingObject/getListCameraByGroups', [
    formState.groupCameraId,
  ]);
  if (response) {
    await dispatch('identificationEvent/getDataChart', formState);
  }
});
const getLabelByChartOption = (key) => {
  switch (formState.chartBy) {
    case STATISTICAL_OBJECT.WEEK:
      return key;
    case STATISTICAL_OBJECT.MONTH:
      return key;
    case STATISTICAL_OBJECT.QUARTER:
      return key;
    case STATISTICAL_OBJECT.YEAR:
      return key;
    default:
      return key;
  }
};
watch(
  () => chartByGender.value,
  () => {
    const columns = chartByGender.value.columns.map((item) =>
      getLabelByChartOption(item)
    );
    const female = chartByGender.value.female;
    const male = chartByGender.value.male;
    const unknown = chartByGender.value.unknown;
    genderChart.value.dataset.source = columns.map((item, index) => [
      item,
      male[index],
      female[index],
      unknown[index],
    ]);
    genderChart.value.dataset.source.unshift([
      'gender',
      t('intrusion-event.male'),
      t('intrusion-event.female'),
      t('intrusion-event.other'),
    ]);
  }
);
watch(
  () => chartByCamera.value,
  () => {
    if (!chartByCamera.value.chartByCamera) {
      chartByCamera.value.chartByCamera = [];
    }
    // Tên label
    cameraChart.value.yAxis.data = chartByCamera.value.chartByCamera.map(
      (item) => item.key_as_string
    );

    // Danh sách camera
    const listCamera = chartByCamera.value.chartByCamera.map((item) => {
      return item.camera_id_terms.buckets.map((item) => {
        return item.key;
      });
    });
    columns.value = [...new Set(listCamera.flat())];
    columns.value = columns.value.map((id) => {
      if (id === 'Other') {
        return t('intrusion-event.other');
      }
      let item = cameras.value.find((obj) => obj.id === id);
      return item ? item.name : null;
    });
    // End Danh sách camera

    // Xử lý data chính
    const listData = chartByCamera.value.chartByCamera.map((item) => {
      return item.camera_id_terms.buckets.map((bucket) => {
        return {
          key: item.key_as_string,
          camera: bucket.key,
          doc_count: bucket.doc_count,
        };
      });
    });
    const finalData = Object.groupBy(listData.flat(), ({ camera }) => camera);
    cameraData.value = [];
    for (const [key, value] of Object.entries(finalData)) {
      cameraData.value.push(value);
    }
    cameraData.value.forEach((column) => {
      cameraChart.value.yAxis.data.forEach((item) => {
        let found = false;
        for (let i = 0; i < column.length; i++) {
          if (column[i]['key'] === item) {
            found = true;
            break;
          }
        }
        if (!found) {
          column.push({ key: item, camera: column[0].camera, doc_count: 0 });
        }
      });
    });
    // End xử lý Data chính

    // Sắp xếp lại data chính theo danh sách camera
    cameraData.value.forEach((item) => {
      item.sort((a, b) => {
        return (
          cameraChart.value.yAxis.data.indexOf(a.key) -
          cameraChart.value.yAxis.data.indexOf(b.key)
        );
      });
    });

    // List tổng camera theo ngày
    totalCameraData.value = [];
    chartByCamera.value.chartByCamera.forEach((item) => {
      totalCameraData.value.push(item.doc_count);
    });
    // End List tổng camera theo ngày
    cameraChart.value.series = columns.value.map((name, sid) => {
      return {
        name,
        type: 'bar',
        stack: 'total',
        barMaxWidth: 19,
        label: {
          show: true,
          formatter: (params) =>
            params.value && Math.round(params.value * 1000) / 10 > 5
              ? Math.round(params.value * 1000) / 10 + '%'
              : '',
        },
        emphasis: {
          focus: 'series',
        },
        data: cameraData.value[sid].map((d, did) =>
          totalCameraData.value[did] <= 0
            ? 0
            : d.doc_count / totalCameraData.value[did]
        ),
      };
    });
  }
);
const changeGroupCamera = () => {
  formState.cameraIds = [];
  dispatch('tracingObject/getListCameraByGroups', [formState.groupCameraId]);
  getChartData();
};
const resetData = () => {
  formState.groupCameraId = null;
  formState.cameraIds = [];
  formState.chartBy = null;
  formState.dateFrom = null;
  formState.dateTo = null;
  refresh.value = true;
};
const typeDatePicker = ref('date');
const formatDatePicker = ref('DD/MM/YYYY HH:mm:ss');
const getChartData = async () => {
  await dispatch('identificationEvent/getDataChart', formState);
  let query = {
    ...route.query,
    groupCameraId: formState.groupCameraId,
    chartBy: formState.chartBy,
    dateFrom: formState.dateFrom,
    dateTo: formState.dateTo,
    cameraIds: formState.cameraIds.join(','),
  };
  await router.push({
    query: query,
  });
};

const onChangeChartBy = async (val) => {
  setDefaultDateForm(formState, val);
  await dispatch('identificationEvent/getDataChart', formState);
  let query = {
    ...route.query,
    groupCameraId: formState.groupCameraId,
    chartBy: formState.chartBy,
    dateFrom: formState.dateFrom,
    dateTo: formState.dateTo,
    cameraIds: formState.cameraIds.join(','),
  };
  await router.push({
    query: query,
  });
};

watch(
  () => formState.chartBy,
  (val) => {
    switch (formState.chartBy) {
      case STATISTICAL_OBJECT.YEAR:
        typeDatePicker.value = 'year';
        formatDatePicker.value = 'YYYY';
        break;
      case STATISTICAL_OBJECT.WEEK:
        typeDatePicker.value = 'week';
        formatDatePicker.value = null;
        break;
      case STATISTICAL_OBJECT.MONTH:
        typeDatePicker.value = 'month';
        formatDatePicker.value = 'MM/YYYY';
        break;
      case STATISTICAL_OBJECT.QUARTER:
        typeDatePicker.value = 'quarter';
        formatDatePicker.value = null;
        break;
      default:
        typeDatePicker.value = 'date';
        formatDatePicker.value = 'DD/MM/YYYY HH:mm:ss';
    }
  }
);
const disabledDateFrom = (current) => {
  return current && current > dayjs(formState.dateTo).endOf('day');
};
const disabledDateTo = (current) => {
  return current && current < dayjs(formState.dateFrom).startOf('day');
};
const keyFilterCamera = ref('');
const onFilterCamera = (val) => {
  keyFilterCamera.value = val;
};
const onBlurCamera = (val) => {
  keyFilterCamera.value = '';
};
const statisticalOptions = [
  { value: 'Y', label: t('intrusion-event.year') },
  { value: 'Q', label: t('intrusion-event.quarter') },
  { value: 'M', label: t('intrusion-event.month') },
  { value: 'W', label: t('intrusion-event.week') },
  { value: 'D', label: t('intrusion-event.day') },
];
const STATISTICAL_OBJECT = {
  YEAR: 'Y',
  QUARTER: 'Q',
  MONTH: 'M',
  WEEK: 'W',
  DAY: 'D',
};
</script>
<style lang="scss">
.dashboard-intrusion .ant-select-selector {
  border-color: #444459 !important;
  height: 39px !important;
  border-radius: 4px !important;
  overflow-x: auto !important;
}
.dashboard-intrusion .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
  height: 39px !important;
  line-height: 39px !important;
}
.dashboard-intrusion .ant-select-selector .ant-select-selection-item {
  line-height: 39px !important;
}
.dashboard-intrusion .ant-select-selector .ant-select-selection-placeholder {
  line-height: 39px !important;
}
.dashboard-intrusion .ant-select-selector .ant-select-selection-overflow {
  flex-wrap: nowrap;
  height: 39px !important;
}
.dashboard-intrusion .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
  border-radius: 20px;
  border-style: none;
  margin-right: 0.25rem;
}
.dashboard-intrusion .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .ant-select-selection-item-content {
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.dashboard-intrusion .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .anticon-close {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.dashboard-intrusion ::-webkit-scrollbar-thumb, .dashboard-intrusion ::-webkit-scrollbar-thumb:horizontal {
  background: #cbd5e1 !important;
  border-radius: 8px !important;
}
.dashboard-intrusion ::-webkit-scrollbar-thumb:hover, .dashboard-intrusion ::-webkit-scrollbar-thumb:horizontal:hover {
  background: #cbd5e1;
}
.dashboard-intrusion ::-webkit-scrollbar:horizontal {
  height: 3px;
}
.dashboard-intrusion .ant-select-multiple .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 2px;
  top: 0px;
  right: 0.25rem;
}
.dashboard-intrusion .ant-select-multiple .ant-select-selection-placeholder {
  left: 17px;
}
.dashboard-intrusion .ant-picker {
  border-color: #444459 !important;
  border-radius: 4px !important;
  height: 39px !important;
  line-height: 39px !important;
  padding-left: 17px !important;
}
</style>
