import mutations from './mutations';
import ConstantAPI, { EMS_SEVICE } from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { notification } from 'ant-design-vue';
import { checkResponse } from '@/util/common-utils';
import { i18n } from '@/main';

const state = () => ({
  treeGroup: [],
  treeAllGroup: [],
  loading: false,
  error: null,
  group: null,
  api: ConstantAPI.io_group,
  apiWarning: ConstantAPI.warning,
  groups: [],
  selectedGroup: null,
  listIOInGroup: [],
  action: 0,
  warningLevel: [],
  page: 0,
  size: 10,
  listAllIO: [],
  listGroup: [],
  listGroupByUser: [],
  totalIO: 0,
  totalIOInGroup: 0,
  listSearchAdvanced: [],
  totalSearchAdvanced: 0,
  listGroupKeyValue: {},
  listGroupKeyValueByUser: {},
  listIONotInGroup: [],
  totalIONotInGroup: 0,
  listDataSocialMayKnown:[],
  listDataSocialSimilar:[],
  notHaveAccess: true
});
const addKey = (data) => {
  for (let item of data) {
    item.key = item.id;
    if (item.children && item.children.length > 0) {
      addKey(item.children);
    }
  }
};
const actions = {
  changeAction({ state }, action) {
    state.action = action;
  },
  async getWarningLevel({ state, commit }) {
    try {
      const response = await DataService.callApi(state.apiWarning.GET);
      return commit('getWarningLevelSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  selectGroup({ state }, idGroup) {
    if (!state.selectedGroup || idGroup !== state.selectedGroup.id) {
      if (idGroup) {
        for (let i in state.listGroup) {
          if (state.listGroup[i].id == idGroup) {
            state.selectedGroup = state.listGroup[i];
            break;
          }
        }
      } else {
        state.selectedGroup = null;
      }
      state.page = 0;
      state.listIOInGroup = [];
    }
  },
  async getTreeGroup({ commit, state }, params) {
    try {
      const response = await DataService.callApi(state.api.GET_TREE, null, {
        type: '1',
        ...params,
      });
      addKey(response.data);
      return commit('fetchDataSuccess', response.data);
    } catch (err) {
      commit('fetchDataErr', err);
    }
  },

  async preCreate({ commit }) {
    commit('preCreate');
  },
  resetForm({ state }) {
    state.group = null;
  },
  async create({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
      payload.parentId =
        payload.parent && payload.parent.key ? payload.parent.key : null;
      delete payload.parent;
      const response = await DataService.callApi(state.api.CREATE, {
        ...payload,
        type: 1,
      });
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('group-management.create-group-success'),
            duration: 4,
          });
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('group-management.create-group-fail'),
            duration: 4,
          });
        }
      );
      return !success ? success : response.data;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async preView({ commit, state }, row) {
    try {
      state.loading = true;
      const response = await DataService.get(`${EMS_SEVICE}/groups/${row.id}`);
      const responseIO = await DataService.get(
        `${EMS_SEVICE}/groups/${row.id}/io`
      );
      commit('setFormValue', {
        group: { ...response.data, children: row.children },
        ioGroup: responseIO.data,
      });
    } catch (e) {
      state.loading = false;
    }
  },
  async update({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
      let data = state.selectedGroup;
      data.name = payload.name;
      data.description = payload.description;
      data.warningLevel = payload.warningLevel;
      data.parentId =
        payload.parent && payload.parent.key ? payload.parent.key : null;
      const response = await DataService.callApi(state.api.UPDATE, {
        ...data,
        type: 1,
      });
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('group-management.update-group-success'),
            duration: 4,
          });
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('group-management.update-group-fail'),
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getListIO({ state, commit }, data) {
    try {
      state.loading = true;
      const response = await DataService.get(
        state.api.GET_LIST_ALL_IO.url,
        data
      );
      if (response.data && response.data.content) {
        state.listIOInGroup =
          data.page === 0
            ? response.data.content
            : state.listIOInGroup.concat(response.data.content);
        state.notHaveAccess = false
      } else {
        state.listIOInGroup = data.page === 0 ? [] : state.listIOInGroup;
        state.notHaveAccess = true
      }
      state.totalIOInGroup = response.data ? response.data.totalElements : null;
      state.loading = false;
    } catch (err) {
      console.log(err);
      commit('fetchDataErr', err);
    }
  },
  async delete({ state }, group) {
    try {
      const { t } = i18n.global;
      const response = await DataService.delete(
        state.api.DELETE_GROUP_IO.url + group.id
      );
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('group-management.delete-group-success'),
            duration: 4,
          });
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('group-management.delete-group-fail'),
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async deleteIOInGroup({ state, commit }, ioId) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
      const response = await DataService.delete(
        state.api.DELETE_IO_IN_GROUP.url +
          `?groupId=${state.selectedGroup.id}&ioId=${ioId}`
      );
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          let idx = state.listIOInGroup.findIndex((el) => el.id === ioId);
          if (idx > -1) {
            state.listIOInGroup.splice(idx, 1);
          }
          notification.success({
            message: t('common.notification'),
            description: t('group-management.delete-io-success'),
            duration: 4,
          });
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('group-management.delete-io-fail'),
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getListIOByCondition({ state, commit }, { payload, isViewMore }) {
    try {
      const response = await DataService.get(
        state.api.GET_LIST_ALL_IO.url,
        payload
      );
      if (isViewMore) {
        state.listAllIO = response.data.content
          ? state.listAllIO.concat(response.data.content)
          : state.listAllIO;
      } else {
        state.listAllIO = response.data.content ? response.data.content : [];
      }
      state.totalIO = response.data.totalElements;
    } catch (err) {
      console.log(err);
      commit('fetchDataErr', err);
    }
  },
  async getListIONotInGroup({ state, commit }, { payload, isViewMore }) {
    try {
      const response = await DataService.get(
        state.api.GET_LIST_IO_NOT_IN_GROUP.url,
        payload
      );
      if (isViewMore) {
        state.listIONotInGroup = response.data.content
          ? state.listIONotInGroup.concat(response.data.content)
          : state.listIONotInGroup;
      } else {
        state.listIONotInGroup = response.data.content
          ? response.data.content
          : [];
      }
      state.totalIONotInGroup = response.data.totalElements;
    } catch (err) {
      console.log(err);
      commit('fetchDataErr', err);
    }
  },
  async addIOToGroup({ state, commit }, listIO) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
      let payload = [];
      for (let i in listIO) {
        payload.push({
          groupId: state.selectedGroup.id,
          referenceId: listIO[i].id,
          type: listIO[i].type,
        });
      }
      const response = await DataService.callApi(
        state.api.ADD_IO_TO_GROUP,
        payload
      );
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('group-management.create-io-success'),
            duration: 4,
          });
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: t('group-management.create-io-fail'),
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getAllTreeGroup({ state }) {
    const response = await DataService.callApi(state.api.GET_TREE, null, {
      type: '1',
    });
    checkResponse(response, () => {
      addKey(response.data);
      state.treeAllGroup = response.data;
    });
  },
  async getAllGroup({ state }) {
    const response = await DataService.callApi(state.api.GET_ALL_GROUP);
    checkResponse(response, () => {
      state.listGroup = response.data;
      response.data.forEach((e) => {
        state.listGroupKeyValue[e.id] = e.name;
      });
    });
  },
  async getAllGroupByUser({ state }) {
    const response = await DataService.callApi(state.api.GET_GROUP_BY_USER);
    checkResponse(response, () => {
      state.listGroupByUser = response.data;
      response.data.forEach((e) => {
        state.listGroupKeyValueByUser[e.id] = e.name;
      });
    });
  },
  async groupSearchAdvanced({ state, commit }, { payload, isViewMore }) {
    try {
      const { t } = i18n.global;
      const response = await DataService.get(
        state.api.GROUP_SEARCH_ADVANCED.url,
        payload
      );
      let success = false;
      checkResponse(
        response,
        () => {
          success = true;
          if (isViewMore) {
            state.listSearchAdvanced = response.data.content
              ? state.listSearchAdvanced.concat(response.data.content)
              : state.listSearchAdvanced;
          } else {
            state.listSearchAdvanced = response.data.content
              ? response.data.content
              : [];
          }
          state.totalSearchAdvanced = response.data.totalElements;
        },
        () => {
          success = false;
          notification.error({
            message: t('common.notification'),
            description: response.message,
            duration: 4,
          });
        }
      );
      return success;
    } catch (err) {
      console.log(err);
      commit('fetchDataErr', err);
      return false;
    }
  },
  async getListDataSocial({ state },id) {
    state.listDataSocialMayKnown=[],
    state.listDataSocialSimilar=[]
    const response = await DataService.get(`${state.api.GET_LIST_DATA_SOCIAL.url}/${id}`);
    checkResponse(response, () => {
      state.listDataSocialMayKnown = response.data?response.data.mayKnown:[]
      state.listDataSocialSimilar = response.data?response.data.similar:[]
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
