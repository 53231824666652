<template>
  <div id="map"></div>
</template>

<script setup>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { onMounted } from 'vue';
import { ref, defineEmits } from 'vue';
import { MARKER_TYPE,LAYER_MAP } from '@/util/common-constant.js';
import { dataGeoHoangSa } from '@/util/hoangSa';
const emits = defineEmits(['click-marker', 'click-marker-ts']);
const props = defineProps({
  center: {
    type: Object,
    default: () => {
      return { lat: 21.051528423347538, lng: 105.80015809649605 };
    },
  },
  markers: {
    type: Array,
    default: () => [],
  },
  markerTrinhSat: {
    type: Array,
    default: () => [],
  },
  flightPath: {
    type: Array,
    default: () => [],
  },
});
const iconType = {};
iconType[MARKER_TYPE.CAMERA_SELECTED] = {
  icon: require('@/static/img/map/camera.png'),
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full',
  class: 'top-[21px] left-0 ',
};
iconType[MARKER_TYPE.CAMERA_DEFAULT] = {
  icon: require('@/static/img/map/camera-default.png'),
  classAnimate: '',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.DEFAULT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.DO] = {
  icon: require('@/static/img/map/do.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.VANG] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.VANG_SELECTED] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro3_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.CURRENT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px]  bg-[#3A5EF6] !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.TRINH_SAT] = {
  icon: require('@/static/img/map/scout.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro4_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.LOCATION] = {
  icon: require('@/static/img/map/location.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
let map = null;
let markers = [];
let markerTrinhSat = [];
const polygonData = dataGeoHoangSa.map((point) => [point.lat, point.lng]);
const icon = L.divIcon({
  className: 'custom-div-icon',
  html: `<h3 style="color: black;">Q.Đ Hoàng Sa</h3>`,
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});


const addPolygon = (polygonCoordinates, options = {}) => {
  const polygon = L.polygon(polygonCoordinates, options).addTo(map);
  return polygon;
};
onMounted(() => {
  map = L.map('map').setView([props.center.lat, props.center.lng], 12);
  L.tileLayer(LAYER_MAP, {
    maxZoom: 19,
  }).addTo(map);
  props.markers.forEach((markerData) => {
    let mk = addMarker(markerData);
    markers.push(mk);
  });
  L.marker([15.7776774770001, 111.206695674], { icon: icon }).addTo(map);
  addPolygon(polygonData, {
    color: '#9BB1C0',
    fillColor: '#94C0E0',
    fillOpacity: 1,
    weight: 2,
  });
  if (props.markerTrinhSat.length > 0) {
    setMarkerTrinhSat(props.markerTrinhSat);
  }

  if (props.flightPath && props.flightPath.length > 0) {
    createLineString(props.flightPath);
  }
});

const addMarker = (markerData) => {
  let el = buildContent(markerData);
  const icon = L.divIcon({
    html: el,
    className: 'custom-marker',
    iconSize: [30, 30],
  });
  const marker = L.marker([markerData.lat, markerData.lng], {
    icon: icon,
  }).addTo(map);
  marker.on('click', () => {
    if (markerData.type === MARKER_TYPE.TRINH_SAT) {
      emits('click-marker-ts', markerData);
    } else {
      emits('click-marker', markerData);
    }
  });
  return marker;
};
function buildContent(property) {
  const content = document.createElement('div');
  content.classList = `marker-goong group absolute`;
  content.id = `marker-goong-${property.id}`;
  if (property.infoWindow) {
    content.innerHTML = `
        <div class="relative icon-marker block h-[30px] w-[30px]">
          <span class="${iconType[property.type].classAnimate}"></span>
            <img src="${
              iconType[property.type].icon
            }" class="absolute z-1 h-[30px] w-[30px]"/>
        </div>
        <div class="details absolute bottom-[46px] flex ${
          property.showInfo
            ? 'opacity-[1]'
            : 'group-hover:opacity-[1] opacity-[0]'
        } flex-col " >
          <div class="${
            property.showInfo ? 'flex' : 'hidden group-hover:flex'
          } ">${property.infoWindow}</div>
          <div class="w-[20px] h-[20px] bg-[#0F0F15] ${
            property.showInfo ? 'block' : 'group-hover:block hidden'
          } absolute bottom-[-8px] after-detail" ></div>
        </div>
        `;
  } else {
    content.innerHTML = `
      <div class="relative icon-marker block h-[30px] w-[30px]">
          <span class="${iconType[property.type].classAnimate}"></span>
            <img src="${
              iconType[property.type].icon
            }" class="absolute z-1 h-[30px] w-[30px]"/>
        </div>
          `;
  }
  return content;
}
const removeAllMarker = () => {
  markers.forEach((marker) => marker.remove());
  markers = [];
};

const setMarker = (list) => {
  removeAllMarker();
  list.forEach((markerData) => {
    let mk = addMarker(markerData);
    markers.push(mk);
  });
};

const moveToLocation = (lat, lng) => {
  map.flyTo([lat, lng], 14);
};

const createLineString = (lineString) => {
  const latLngs = lineString.map(([lng, lat]) => [lat, lng]);
  L.polyline(latLngs, { color: 'red', weight: 2 }).addTo(map);
};

const clearMarkerTrinhSat = () => {
  markerTrinhSat.forEach((marker) => marker.remove());
  markerTrinhSat = [];
};

const setMarkerTrinhSat = (list) => {
  clearMarkerTrinhSat();
  list.forEach((markerData) => {
    let mk = addMarker(markerData);
    markerTrinhSat.push(mk);
  });
};

const resize = () => {
  map.invalidateSize();
};

const fitBounds = () => {
  const bounds = L.latLngBounds([]);
  markers.forEach((marker) => bounds.extend(marker.getLatLng()));
  markerTrinhSat.forEach((marker) => bounds.extend(marker.getLatLng()));
  if (bounds.isValid()) {
    map.fitBounds(bounds, { padding: [10, 10] });
  }
};

defineExpose({
  moveToLocation,
  setMarker,
  createLineString,
  setMarkerTrinhSat,
  clearMarkerTrinhSat,
  resize,
  fitBounds,
});
</script>

<style>
#map {
  height: 100%;
}
#map .mapboxgl-popup-content {
  background-color: #0f0f15;
  padding: 16px !important;
}
#map .mapboxgl-popup-content .mapboxgl-popup-close-button {
  right: 4px;
  top: 4px;
  color: #fff;
  font-size: 20px;
}
#map .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  grid-gap: 22px;
  gap: 22px;
  width: max-content;
  overflow: auto;
  z-index: 1;
}
#map .mapboxgl-popup-tip {
  border-top-color: #0f0f15;
}
#map .marker-goong {
  align-items: flex-end;
  background-color: transparent;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  transition: all 0.2s ease-out;
  width: 30px;
}
#map .marker-goong .details {
  background-color: #0f0f15;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  width: auto;
  align-items: center;
  width: fit-content;
  flex: 1;
  transition: all 0.3s ease-out;
}
#map .marker-goong .details .after-detail {
  transform: rotate(45deg);
}
#line-map .leaflet-top, .leaflet-bottom {
  display: none;
}
</style>
