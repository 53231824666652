<template>
  <div class="h-[calc(100%-40px)]">
    <cds-main class="">
      <div class="flex gap-3 w-full my-[20px] px-[16px] h-full">
        <CoordinationList />
        <Map />
        <WalkieTalkie />
      </div>
    </cds-main>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import CoordinationList from './components/coordination-list/coordination-list.vue';
import Map from './components/map.vue';
import WalkieTalkie from './components/walkie-talkie/walkie-talkie.vue';
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { TYPE_MESSAGE, PEER_TYPE, TAB_WALKIE } from '@/util/common-constant';
import { decryptData } from '@/dataService/dataService';
import axios from 'axios';
const { state, dispatch } = useStore();
const { t } = useI18n();
const route = useRoute();
const connection = ref(null);
const router = useRouter();
const params = computed(() => state.coordinator.params);
watch(params, (newValue) => {
  router.push({
    query: newValue,
  });
});
onMounted(async () => {
  await dispatch('getUserInfo');
  connection.value = new WebSocket(
    `wss://${decryptData(
      window.VUE_APP_IP
    )}/chat-service/ws_chat?access_token=${
      state.auth.tokenInfo.accessToken
    }&id=${state.auth.userInfo.id}`
  );
  connection.value.onmessage = async (event) => {
    let data;

    try {
      data = JSON.parse(event.data);
    } catch (e) {
      console.error('Error parsing WebSocket message data:', e);
      return;
    }
    let typeMessage = data.updateType;
    switch (typeMessage) {
      case TYPE_MESSAGE.UPDATE_NEW_MESS:
        {
          const { senderId, receiverId } = data.content;
          if (Number(route.query.type) === 1) {
            if (
              senderId === route.query.id &&
              receiverId === state.auth.userInfo.id
            ) {
              dispatch('coordinator/updateMessage', data.content);
            } else if (route.query.id === receiverId) {
              dispatch('coordinator/updateMessage', data.content);
            }
          } else {
            if (receiverId === route.query.id) {
              dispatch('coordinator/updateMessage', data.content);
            }
          }
          listenGetDialog();
        }
        break;
      case TYPE_MESSAGE.READ_MESS:
        {
          const { dialogId, dialogType, receiverId, senderId } = data.content;
          if (receiverId === route.query.id || senderId === route.query.id) {
            listenUpdateNewMessage(dialogId, dialogType);
          }
          listenGetDialog();
        }
        break;
      case TYPE_MESSAGE.DELETE_MESS:
        {
          const { dialogId, dialogType } = data.content.message;
          listenUpdateNewMessage(dialogId, dialogType);
          listenGetDialog();
        }
        break;
      case TYPE_MESSAGE.UPDATE_MODIFY_CONTACT:
        listenUpdateLike();
        break;
      case TYPE_MESSAGE.UPDATE_NEW_PHONE_ROOM:
        {
          const { roomId, senderId, receiverType, receiverId, receiverBusy } =
            data.content;
          localStorage.setItem('receiverType', receiverType);
          localStorage.setItem('roomId', roomId);
          dispatch('coordinator/handleGetRoomID', roomId);
          if (!receiverBusy) {
            if (receiverType === 2) {
              handleSendParams(receiverId, receiverType);
              dispatch('coordinator/handleChangeStatus', receiverId);
            } else {
              handleSendParams(senderId, receiverType);
              dispatch('coordinator/handleChangeStatus', senderId);
            }
            dispatch('coordinator/joinRoom', {
              roomId: roomId,
            });
            dispatch('coordinator/joinChannel', {
              appId: decryptData(window.VUE_APP_API_AGORA_ID),
              channel: roomId,
              token: null,
              uuid: null,
            });
          }
        }
        break;
      case TYPE_MESSAGE.UPDATE_LEAVE_ROOM:
        {
          const { roomId, isDeleteRoom } = data.content;
          const receiverType = localStorage.getItem('receiverType');
          let value = {
            roomId: roomId,
          };
          if (Number(receiverType) === PEER_TYPE.USER) {
            leaveAndCleanUp(value);
          } else {
            if (isDeleteRoom) {
              leaveAndCleanUp(value);
            }
          }
        }
        break;
      case TYPE_MESSAGE.UPDATE_DELETE_HISTORY:
        {
          const { dialogId, dialogType } = data.content;
          listenUpdateNewMessage(dialogId, dialogType);
          listenGetDialog();
        }
        break;
      default:
        break;
    }
  };

  connection.value.ondisconnect = async () => {
    await dispatch('getUserInfo');
    connection.value = new WebSocket(
      `wss://${decryptData(
        window.VUE_APP_IP
      )}/chat-service/ws_chat?access_token=${
        state.auth.tokenInfo.accessToken
      }&id=${state.auth.userInfo.id}`
    );
  };
  dispatch('setTitleHeader', t('coordinator.header'));
  // Check if geolocation is supported by the browser
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        updateLocation(position);
      },
      (err) => {
        console.log(err);
        navigator.geolocation.watchPosition(
          function (position) {
            updateLocation(position);
          },
          function (error) {
            console.error('Error getting user location:', error);
          }
        );
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  } else {
    // Geolocation is not supported by the browser
    console.error('Geolocation is not supported by this browser.');
  }
  getListChannels();
  getListContacts();
  handleCloseRoom();
});
onUnmounted(() => {
  connection.value.close();
  dispatch('coordinator/resetState');
});
const updateLocation = async (position) => {
  // Get the user's latitude and longitude coordinates
  const lat = position.coords.latitude;
  const lng = position.coords.longitude;
  // Update the map with the user's new location
  axios
    .get(
      `https://rsapi.goong.io/Geocode?latlng=${lat}, ${lng}&api_key=${window.VUE_APP_API_GOONG_MAP_API_KEY}`
    )
    .then((res) => {
      let data = {
        latitude: lat,
        longitude: lng,
        address:
          res.data.results.length > 0
            ? res.data.results[0].formatted_address
            : '',
      };
      dispatch('coordinator/updateLocation', data);
    })
    .catch((e) => {
      console.log(e);
      let data = {
        latitude: lat,
        longitude: lng,
        address: '',
      };
      dispatch('coordinator/updateLocation', data);
    });
};
const listenUpdateNewMessage = async (dialogId, dialogType) => {
  dispatch('coordinator/getMessagesByDialogs', {
    dialogId,
    dialogType,
    isChated: true,
  });
};
const listenGetDialog = () => {
  dispatch('coordinator/getListDialogs', {
    payload: { page: 0, size: 10 },
    firstData: true,
  });
};
const listenUpdateLike = () => {
  dispatch('coordinator/getListContacts');
};
const getListChannels = () => {
  dispatch('coordinator/getListChannels', {
    payload: { page: 0, size: 10, q: '', activeOnly: true },
    firstData: true,
  });
};
const getListContacts = () => {
  dispatch('coordinator/getListContacts');
};
const leaveAndCleanUp = (value) => {
  dispatch('coordinator/leaveRoom', value).then(() => {
    dispatch('coordinator/handleChangeStatus', null);
    dispatch('coordinator/leaveChannel');
    localStorage.removeItem('receiverType');
    localStorage.removeItem('roomId');
  });
};
const handleSendParams = (senderId, receiverType) => {
  let query = {
    id: senderId,
    type: receiverType,
    tab_list: route.query.tab_list,
    tab_walkie: TAB_WALKIE.WALKIE,
  };
  dispatch('coordinator/handleSetParams', query);
};
const handleCloseRoom = async () => {
  const roomId = localStorage.getItem('roomId');
  if (roomId) {
    let value = {
      roomId: roomId,
    };
    await dispatch('coordinator/leaveRoom', value);
  }
};
</script>
