<template>
  <div class="w-full h-screen w-full flex">
    <div class="w-[70%]">
      <img
        alt="logo_bg"
        src="@/static/img/auth/bg_login.png"
        class="w-full h-full"
      />
    </div>
    <div class="w-[30%] min-w-[518px] h-full bg-ems-gray700 overflow-auto">
      <div class="w-full px-[82px] flex flex-col justify-center h-full">
        <img
          src="@/static/img/auth/logo_login.svg"
          width="225"
          :height="11"
          alt="ROS-logo"
          class="mx-auto"
        />
        <img
          src="@/static/img/auth/text_logo.svg"
          width="225"
          :height="11"
          alt="text-logo"
          class="mx-auto"
        />
        <div class="form-login relative">
          <div class="px-[27px] pt-[51px] w-full">
            <a-form @finish="handleSubmit" :model="formState" layout="vertical">
              <a-form-item name="username">
                <div class="mb-3">
                  <span class="text-sm text-[#7B7B7B]">{{
                    t('sign-in.username')
                  }}</span>
                </div>
                <a-input
                  type="text"
                  v-model:value="formState.username"
                  :placeholder="t('sign-in.username')"
                  :class="
                    error ? '!border-1 !border-solid !border-ems-main1' : ''
                  "
                />
                <span
                  class="text-[12px] text-ems-main2 mt-1 block"
                  v-if="error"
                  >{{ t('sign-in.input-again') }}</span
                >
              </a-form-item>
              <a-form-item name="password">
                <div class="mb-3">
                  <span class="text-sm text-[#7B7B7B]">{{
                    t('sign-in.password')
                  }}</span>
                </div>
                <a-input-password
                  type="password"
                  v-model:value="formState.password"
                  :placeholder="t('sign-in.password')"
                  :class="
                    error ? '!border-1 !border-solid !border-ems-main1' : ''
                  "
                />
                <span
                  class="text-[12px] text-ems-main2 mt-1 block"
                  v-if="error"
                  >{{ t('sign-in.input-again') }}</span
                >
              </a-form-item>
              <!-- <div
                class="grid grid-cols-1 md:grid-cols-2 gap-2 text-ems-gray500 text-normal text-sm"
              >
                <span class="cursor-pointer">{{
                  t('sign-in.login-by-sso')
                }}</span>
                <span class="cursor-pointer md:justify-self-end">{{
                  t('sign-in.forgot-pass')
                }}</span>
              </div> -->
              <!-- <div class="flex mt-[18px] cursor-pointer">
                <img
                  src="@/static/img/auth/icon-face-id.svg"
                  width="18"
                  height="18"
                  alt="icon-face-id"
                  class="mr-2"
                />
                <span class="text-sm text-ems-main1 font-normal">{{
                  t('sign-in.biometrics')
                }}</span>
              </div> -->
              <a-form-item>
                <ClipButton
                  html-type="submit"
                  type="primary"
                  size="small"
                  class="w-full h-[54px] mt-[31px]"
                >
                  <span class="text-ems-white"> {{ t('sign-in.login') }}</span>
                </ClipButton>
              </a-form-item>
            </a-form>
          </div>
        </div>

        <span class="mt-[34px] text-sm text-ems-main2 block text-center">
          {{ error }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ClipButton from '@/components/buttons/ClipButton.vue';
const { t } = useI18n();

const { state, dispatch } = useStore();
// const isLoading = computed(() => state.auth.loading);
const error = computed(() => state.auth.error);
const handleSubmit = () => {
  dispatch('login', formState);
};
const formState = reactive({
  username: '',
  password: '',
});
</script>

<style lang="scss">

</style>
