<template>
  <div class="list-event relative h-full">
    <div
      class="gap-[20px] px-[24px] py-[20px] bg-ems-gray800 border-1px border-solid border-ems-tag1 border-t-0"
    >
      <div class="grid grid-cols-12 items-center gap-[20px]">
        <SelectSearchList
          class="col-span-2"
          :placeholder="t('object_tracing.cam-group')"
          :options="groupCameras"
          v-model:value="formState.groupCameraId"
          :allow-clear="true"
          :label-prop="'name'"
          :value-prop="'id'"
          @change="changeGroupCamera"
          :isLabelI18n="false"
        />
        <SelectSearchList
          class="col-span-3 !h-[39px]"
          :placeholder="t('object_information.camera')"
          :options="cameras"
          isShowSearch
          v-model:value="formState.listCamera"
          :allow-clear="true"
          :label-prop="'name'"
          :value-prop="'id'"
          multiple
          :isLabelI18n="false"
          :maxTagCount="1"
          :max-tag-text-length="20"
        />
        <div class="col-span-7">
          <clip-button
            class="flex items-center text-sm text-ems-white text-nowrap h-[39px] !px-[30px] w-[fit-content] justify-self-end"
            :type="'secondary'"
            @click="resetData"
          >
            <cds-feather-icons
              type="rotate-cw"
              size="16"
              class="!text-ems-white mr-2"
            />
            {{ $t('common.refresh') }}
          </clip-button>
        </div>
      </div>
    </div>

    <a-spin :spinning="isLoading">
      <Empty
        v-if="!listEvent || listEvent.length === 0"
        :description="t('common.no_data_displayed')"
        class="pt-[200px] mx-auto 2xl:col-span-6 col-span-5"
      />
      <div
        v-else
        class="grid grid-cols-10 gap-[20px] px-[20px] py-[16px] bg-ems-gray800 h-full"
      >
        <div
          class="2xl:col-span-4 col-span-5 h-full relative p-[16px] bg-ems-gray700 rounded-lg h-full overflow-auto hidden-scroll detail-event"
        >
          <a-spin :spinning="loadingDetail">
            <div class="relative">
              <div
                class="h-full absolute z-3 top-0 left-[34px] flex items-center justify-between"
              >
                <img
                  src="/assets/ems/prev-border.png"
                  alt="img"
                  width="38"
                  height="38"
                  :class="
                    selectedIndex === 0
                      ? 'disabled-icon'
                      : 'cursor-pointer hover:opacity-80'
                  "
                  @click="prevImg()"
                />
              </div>
              <div
                class="absolute z-3 top-0 right-[34px] h-full flex items-center justify-between"
              >
                <img
                  src="/assets/ems/next-border.png"
                  alt="img"
                  width="38"
                  height="38"
                  :class="
                    selectedIndex === total - 1
                      ? 'disabled-icon'
                      : 'cursor-pointer hover:opacity-80'
                  "
                  @click="nextImg()"
                />
              </div>
              <Image
                v-if="item.image"
                :height="350"
                :src="item.image"
                class="relative !h-[350px] !w-full object-cover rounded-lg"
                overlayClassName="popup-confirm-tb-edit"
              >
                <template #previewMask>
                  <div
                    class="flex gap-20px justify-center items-center h-full rounded-lg"
                  >
                    <cds-feather-icons type="eye" size="20" />
                  </div>
                </template>
              </Image>
              <img
                v-else
                :src="require('@/static/img/no-img-big.png')"
                alt="img"
                class="rounded-[8px] w-full h-[350px] flex-shrink-0 object-cover"
              />
            </div>
            <div class="mt-[20px]">
              <div class="flex items-center gap-1">
                <span class="text-base font-semibold text-ems-white uppercase"
                  >{{
                    item.type === OBJECT_TYPE.HUMAN
                      ? t('intrusion-event.info')
                      : t('identification-event.ident_info')
                  }}
                  :
                </span>
                <span
                  v-if="!item.iOName && !item.iOId"
                  class="text-ems-white font-semibold"
                  >{{ item.id }}</span
                >
                <a-popover
                  v-else
                  placement="bottom"
                  overlayClassName="popup-view-more"
                >
                  <template #content>
                    <cds-button key="back" type="light" size="small">
                      {{ t('common.view-more') }}
                    </cds-button>
                  </template>
                  <span
                    class="text-ems-main2 font-semibold cursor-pointer"
                    @click="clickName()"
                    >{{ nameEvt }}</span
                  ></a-popover
                >
              </div>
              <div
                class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
              >
                <span class="text-ems-gray300 w-[150px]">{{
                  t('identification-event.time-ident')
                }}</span>
                <span class="text-ems-gray300 flex justify-end flex-1">{{
                  formatDate(item.createdAt, 'HH:mm:ss - DD/MM/YYYY')
                }}</span>
              </div>
              <div
                class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
              >
                <span class="text-ems-gray300 w-[150px]">{{
                  t('identification-event.status')
                }}</span>
                <span
                  v-if="item.directionType === EVENT_STATUS_VALUE.CHECKIN"
                  class="text-ems-boTro500 border-ems-boTro500 flex justify-end flex-1"
                  >{{ t('identification-event.checkin') }}</span
                >
                <span
                  v-if="item.directionType === EVENT_STATUS_VALUE.CHECKOUT"
                  class="text-ems-boTro4_600 border-ems-boTro4_600 flex justify-end flex-1"
                  >{{ t('identification-event.checkout') }}</span
                >
                <span
                  v-if="item.directionType === EVENT_STATUS_VALUE.APPEAR"
                  class="text-ems-boTro2_600 border-ems-boTro2_600 flex justify-end flex-1"
                  >{{ t('identification-event.appear') }}</span
                >
                <span
                  v-if="item.directionType === EVENT_STATUS_VALUE.INTRUSION"
                  class="text-ems-main1 border-ems-main1 flex justify-end flex-1"
                  >{{ t('identification-event.intrusion') }}</span
                >
              </div>
              <div
                class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
              >
                <span class="text-ems-gray300 w-[150px]">{{
                  t('identification-event.location')
                }}</span>
                <span class="text-ems-gray300 flex justify-end flex-1">{{
                  item.location
                }}</span>
              </div>
              <div v-if="item.type === OBJECT_TYPE.HUMAN">
                <div class="grid grid-cols-2 gap-[16px]">
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('identification-event.age') }}:</span
                    >
                    <span class="text-ems-gray300 flex justify-end flex-1">{{
                      item.humanInfo ? item.humanInfo.face.age : ''
                    }}</span>
                  </div>
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('identification-event.gender') }}:</span
                    >
                    <span class="text-ems-gray300 flex justify-end flex-1">{{
                      item.humanInfo && item.humanInfo.face.gender
                        ? t(GENDER[item.humanInfo.face.gender])
                        : ''
                    }}</span>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-[16px]">
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('identification-event.chungToc') }}:</span
                    >
                    <span class="text-ems-gray300 flex justify-end flex-1">{{
                      item.humanInfo && item.humanInfo.face.race
                        ? t(RACE[item.humanInfo.face.race])
                        : ''
                    }}</span>
                  </div>
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('object_information.group_belong') }}:</span
                    >
                    <span class="text-ems-gray300 flex justify-end flex-1">{{
                      groupStr
                    }}</span>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-[16px]">
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('identification-event.kieuToc') }}:</span
                    >
                    <span class="text-ems-gray300 flex justify-end flex-1">{{
                      item.humanInfo && item.humanInfo.person.hair
                        ? t(HAIR[item.humanInfo.person.hair])
                        : ''
                    }}</span>
                  </div>
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('identification-event.kieuAo') }}:</span
                    >
                    <span class="text-ems-gray300 flex justify-end flex-1">{{
                      item.humanInfo ? kieuAo : ''
                    }}</span>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-[16px]">
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('identification-event.kieuQuan') }}:</span
                    >
                    <span class="text-ems-gray300 flex justify-end flex-1">{{
                      item.humanInfo ? kieuQuan : ''
                    }}</span>
                  </div>
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('identification-event.kieuTui') }}:</span
                    >
                    <span class="text-ems-gray300 flex justify-end flex-1">{{
                      item.humanInfo && item.humanInfo.bag
                        ? t(BAG[item.humanInfo.bag])
                        : ''
                    }}</span>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-[16px]">
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('identification-event.mu') }}:</span
                    >
                    <a-checkbox
                      class="flex justify-end flex-1"
                      v-model:checked="mu"
                      disabled
                    ></a-checkbox>
                  </div>
                  <div
                    class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                  >
                    <span class="text-ems-gray300 w-[150px]"
                      >{{ t('identification-event.kinhMat') }}:</span
                    >
                    <a-checkbox
                      class="flex justify-end flex-1"
                      disabled
                      v-model:checked="kinhMat"
                    ></a-checkbox>
                  </div>
                </div>
              </div>
              <div v-if="item.type === OBJECT_TYPE.VEHICLE">
                <div
                  class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                >
                  <span class="text-ems-gray300 w-[150px]">{{
                    t('object_information.vehicle_owner')
                  }}</span>
                  <span class="text-ems-gray300 flex justify-end flex-1">{{
                    item.vehicleInfo ? item.vehicleInfo.vehicleOwner : ''
                  }}</span>
                </div>
                <div
                  class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                >
                  <span class="text-ems-gray300 w-[150px]">{{
                    t('object_information.vehicle_type')
                  }}</span>
                  <span class="text-ems-gray300 flex justify-end flex-1">{{
                    item.vehicleInfo ? getNameVehicleType : ''
                  }}</span>
                </div>
                <div
                  class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                >
                  <span class="text-ems-gray300 w-[150px]">{{
                    t('object_information.vehicle_brand')
                  }}</span>
                  <span class="text-ems-gray300 flex justify-end flex-1">{{
                    item.vehicleInfo
                      ? item.vehicleInfo.vehicleBrand !== TYPE.UNKNOWN
                        ? item.vehicleInfo.vehicleBrand
                        : t('object_tracing.unknown')
                      : ''
                  }}</span>
                </div>
                <div
                  class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                >
                  <span class="text-ems-gray300 w-[150px]">{{
                    t('object_information.color')
                  }}</span>
                  <span class="text-ems-gray300 flex justify-end flex-1">{{
                    item.vehicleInfo ? vehicleColor : ''
                  }}</span>
                </div>
                <div
                  class="mt-6px border-b-1px border-solid border-ems-gray700 pb-1 flex items-center gap-[20px]"
                >
                  <span class="text-ems-gray300 w-[150px]">{{
                    t('object_information.group_belong')
                  }}</span>
                  <span class="text-ems-gray300 flex justify-end flex-1">{{
                    groupStr
                  }}</span>
                </div>
              </div>
            </div>
          </a-spin>
        </div>

        <div
          class="2xl:col-span-6 col-span-5 max-h-[100%] overflow-auto hidden-scroll pr-1"
          @scroll="onScroll"
          ref="containerEvent"
        >
          <a-spin :spinning="isLoadingList">
            <div
              class="grid 2xl:grid-cols-4 grid-cols-3 gap-[20px] h-[fit-content]"
            >
              <div
                v-for="(data, idx) in listEvent"
                :key="idx"
                class="p-10px rounded-lg bg-ems-gray700 overflow-x-clip cursor-pointer h-[fit-content] border-1px border-solid"
                :class="
                  selectedIndex === idx
                    ? ' border-ems-main2'
                    : ' border-transparent'
                "
                :id="selectedIndex === idx ? 'selected-event' : ''"
                @click="clickItem(idx)"
              >
                <img
                  :src="
                    !data.imageCrop
                      ? require('@/static/img/no-img.png')
                      : data.imageCrop
                  "
                  class="rounded flex-shrink-0 w-full h-[100px] object-cover mb-[8px]"
                />
                <div class="text-[12px] text-ems-gray300 font-semibold">
                  {{ formatDate(data.createdAt, 'DD/MM/YYYY - HH:mm:ss') }}
                </div>

                <div
                  class="text-sm text-ems-gray300 font-semibold uppercase mt-1"
                >
                  {{ data.cameraName }}
                </div>
              </div>
            </div>
          </a-spin>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, reactive, ref, watch, onMounted, onUnmounted } from 'vue';
import Empty from '@/components/empty/index.vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { Image } from 'ant-design-vue';
import {
  OBJECT_TYPE,
  EVENT_STATUS_VALUE,
  FORM_MODE,
  NAVIGATION_TYPE,
  TYPE_EVENT,
  TYPE,
} from '@/util/common-constant';
import { formatDate, getText, trim } from '@/util/common-utils';
import { debounce } from 'lodash';
import { useRouter, useRoute } from 'vue-router';
import SelectSearchList from '@/components/select-search-list/SelectSearchList.vue';
import { RACE, BAG, STYLE, HAIR, COLOR, GENDER } from '@/config/Constant';
import { decryptData } from '@/dataService/dataService';
const { t } = useI18n();
const { state, dispatch } = useStore();

const groupCameras = computed(() => state.tracingObject.listGroupCamera);
const cameras = computed(() => state.tracingObject.listCameraByGroup);
const listEvent = computed(() => state.tracingObject.listIntrusion);
const isLoading = ref(false);
const page = ref(0);
const router = useRouter();
const route = useRoute();
const kieuAo = computed(() => {
  let arr = [];
  if (item.value && item.value.humanInfo && item.value.humanInfo.person) {
    arr = item.value.humanInfo.person.upper.style
      ? [t(STYLE[item.value.humanInfo.person.upper.style])]
      : [];
    if (item.value.humanInfo.person.upper.color) {
      item.value.humanInfo.person.upper.color.forEach((e) => {
        arr.push(t(COLOR[e]));
      });
    }
  }
  return arr.join(', ');
});
const kieuQuan = computed(() => {
  let arr = [];
  if (item.value && item.value.humanInfo && item.value.humanInfo.person) {
    arr = item.value.humanInfo.person.lower.style
      ? [t(STYLE[item.value.humanInfo.person.lower.style])]
      : [];
    if (item.value.humanInfo.person.lower.color) {
      item.value.humanInfo.person.lower.color.forEach((e) => {
        arr.push(t(COLOR[e]));
      });
    }
  }
  return arr.join(', ');
});
const mu = computed(
  () => item.value.humanInfo && item.value.humanInfo.person.hat
);
const kinhMat = computed(
  () => item.value.humanInfo && item.value.humanInfo.person.glasses
);
const vehicleType = computed(() => state.listSelect.vehicleType);
const getNameVehicleType = computed(() => {
  const index = vehicleType.value.findIndex(
    (data) => data.value === item.value.vehicleInfo.vehicleName
  );
  if (index !== -1) {
    return vehicleType.value[index].name;
  }
  return '';
});
const color = computed(() => state.listSelect.color);
const vehicleColor = computed(() => {
  const index = color.value.findIndex(
    (data) => data.value === item.value.vehicleInfo.vehicleColor
  );
  if (index !== -1) {
    return color.value[index].name;
  }
  return '';
});
const listGroup = computed(() => state.ioGroup.listGroup);
const groupStr = computed(() => {
  let str = '';
  if (item.value.ioGroups && listGroup.value) {
    item.value.ioGroups.forEach((item, idx) => {
      const index = listGroup.value.findIndex((group) => group.id === item);
      if (index !== -1) {
        if (idx === 0) {
          str = listGroup.value[index].name;
        } else {
          str = str + ' - ' + listGroup.value[index].name;
        }
      }
    });
  }
  return str;
});
const nameEvt = computed(() => {
  let name;
  if (item.value.type === OBJECT_TYPE.VEHICLE) {
    name = name = item.value.licensePlate
      ? item.value.licensePlate
      : item.value.iOId
      ? item.value.iOId
      : item.value.id
      ? item.value.id
      : '';
  } else {
    name = item.value.iOName
      ? item.value.iOName
      : item.value.iOId
      ? item.value.iOId
      : item.value.id
      ? item.value.id
      : '';
  }

  return name.length > 30 ? `${name.substring(0, 30)}...` : name;
});
const connection = ref(null);
const containerEvent = ref(null);
onMounted(async () => {
  let promises = [
    dispatch('tracingObject/getListGroupCamera'),
    dispatch('listSelect/getAllColor'),
    dispatch('listSelect/getAllVehicleType'),
    dispatch('ioGroup/getAllGroup'),
  ];
  await Promise.all(promises);

  formState.groupCameraId = route.query.groupCameraId
    ? route.query.groupCameraId
    : null;
  await changeGroupCamera();
  formState.listCamera = route.query.cameraIds
    ? route.query.cameraIds.split(',')
    : [];
  selectedIndex.value = 0;
  window.addEventListener('keyup', onKeyUp);
  // WebSocket
  connection.value = new WebSocket(
    `wss://${decryptData(window.VUE_APP_IP)}/socket?access_token=${
      state.auth.tokenInfo.accessToken
    }&id=${state.auth.userInfo.id}`
  );
  connection.value.onmessage = (event) => {
    mappingData(JSON.parse(event.data));
  };
  connection.value.ondisconnect = async () => {
    await dispatch('getUserInfo');
    connection.value = new WebSocket(
      `wss://${decryptData(window.VUE_APP_IP)}/socket?access_token=${
        state.auth.tokenInfo.accessToken
      }&id=${state.auth.userInfo.id}`
    );
  };
  // End WebSocket
});

onUnmounted(() => {
  connection.value.close();
  dispatch('tracingObject/resetListIntrusion', []);
  window.removeEventListener('keyup', onKeyUp);
});

const clickItem = (idx) => {
  loadingDetail.value = true;
  selectedIndex.value = idx;
  setTimeout(() => {
    loadingDetail.value = false;
  }, 300);
};

const mappingData = (data) => {
  if (data.type === TYPE_EVENT.INTRUSION) {
    if (
      (!formState.groupCameraId ||
        (formState.groupCameraId &&
          formState.groupCameraId === data.data.groupCameraId)) &&
      (formState.listCamera.length === 0 ||
        (formState.listCamera.length > 0 &&
          formState.listCamera.includes(data.data.cameraId)))
    ) {
      loadingDetail.value = true;
      let evt = {
        cameraName: data.data.cameraName,
        createdAt: data.data.createdAt,
        directionType: data.data.directionType,
        humanInfo: data.data.humanInfo,
        iOId: data.data.iOId,
        iOName: data.data.iOName,
        id: data.data.id,
        image: data.data.imageUrl,
        imageCrop: data.data.imageCropUrl,
        ioGroup: data.data.ioGroup,
        location: data.data.cameraLocation,
        type: OBJECT_TYPE[data.data.type],
        vehicleInfo: data.data.vehicleInfo,
      };
      dispatch('tracingObject/newIntrusion', evt);
      selectedIndex.value = 0;
      setTimeout(() => {
        loadingDetail.value = false;
      }, 300);
    }
  }
};
const onKeyUp = (e) => {
  if (e.keyCode === 37) {
    onNavigate(NAVIGATION_TYPE.PREV);
  } else if (e.keyCode === 39) {
    onNavigate(NAVIGATION_TYPE.NEXT);
  }
};
const total = computed(() => state.tracingObject.totalIntrusion);
const onNavigate = (navigationType) => {
  if (navigationType === NAVIGATION_TYPE.PREV) {
    prevImg();
  } else {
    nextImg();
  }
};

const loadingDetail = ref(false);

const prevImg = async () => {
  if (selectedIndex.value !== 0) {
    loadingDetail.value = true;
    selectedIndex.value--;
    setTimeout(() => {
      let offset = document
        .getElementById('selected-event')
        .getBoundingClientRect();
      if (
        containerEvent.value &&
        containerEvent.value.scrollTop != 0 &&
        offset.top < 220
      ) {
        containerEvent.value.scroll({
          top: containerEvent.value.scrollTop - 500,
          behavior: 'smooth',
        });
      }
      loadingDetail.value = false;
    }, 200);
  }
};
const isLoadingList = ref(false);
const nextImg = async () => {
  loadingDetail.value = true;
  if (selectedIndex.value !== total.value - 1) {
    if (selectedIndex.value === listEvent.value.length - 1) {
      isLoadingList.value = true;
      page.value += 1;
      let params = {
        groupCameraId: formState.groupCameraId,
        size: formState.size,
        cameraIds: formState.listCamera.join(','),
        page: page.value,
      };
      await dispatch('tracingObject/getIntrusion', {
        params: params,
        reset: false,
      });
      isLoadingList.value = false;
    } else selectedIndex.value++;
  }
  setTimeout(() => {
    let offset = document
      .getElementById('selected-event')
      .getBoundingClientRect();
    if (
      containerEvent.value &&
      offset.top > containerEvent.value.offsetHeight
    ) {
      containerEvent.value.scroll({
        top: containerEvent.value.scrollTop + offset.top - 250,
        behavior: 'smooth',
      });
    }
    loadingDetail.value = false;
  }, 200);
};

const item = computed(() => {
  return listEvent.value[selectedIndex.value]
    ? listEvent.value[selectedIndex.value]
    : {};
});
const selectedIndex = ref(0);

const formState = reactive({
  groupCameraId: null,
  size: 50,
  listCamera: [],
});

const changeGroupCamera = async () => {
  formState.listCamera = [];
  await dispatch(
    'tracingObject/getListCameraByGroups',
    formState.groupCameraId ? [formState.groupCameraId] : []
  );
};
const resetData = () => {
  formState.groupCameraId = null;
  formState.listCamera = [];
};
const getData = async () => {
  isLoading.value = true;
  page.value = 0;
  let params = {
    groupCameraId: formState.groupCameraId,
    cameraIds: formState.listCamera.join(','),
    size: formState.size,
    page: page.value,
  };
  await dispatch('tracingObject/getIntrusion', {
    params: params,
    reset: true,
  });
  router.push({
    query: {
      ...route.query,
      groupCameraId: formState.groupCameraId,
      cameraIds: formState.listCamera.join(','),
    },
  });
  isLoading.value = false;
};
const onScroll = async ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (scrollTop + clientHeight >= scrollHeight - 1) {
    isLoadingList.value = true;
    page.value += 1;
    let params = {
      groupCameraId: formState.groupCameraId,
      size: formState.size,
      cameraIds: formState.listCamera.join(','),
      page: page.value,
    };
    await dispatch('tracingObject/getIntrusion', {
      params: params,
      reset: false,
    });
    isLoadingList.value = false;
  }
};

const clickName = () => {
  if (item.value.iOId) {
    router.push({
      name: '/io-management/object-management/edit',
      query: {
        id: item.value.iOId,
        type: item.value.type,
        mode: FORM_MODE.VIEW,
        tab: 'history',
      },
    });
  }
};

watch(
  () => formState,
  debounce(async () => {
    loadingDetail.value = true;
    await getData();
    selectedIndex.value = 0;
    if (containerEvent.value) {
      containerEvent.value.scroll({ top: 0, behavior: 'smooth' });
    }
    setTimeout(() => {
      loadingDetail.value = false;
    }, 300);
  }, 300),
  {
    deep: true,
  }
);
</script>

<style lang="scss">
.list-event .detail-event .ant-spin-nested-loading {
  height: 100% !important;
}
.list-event .ant-spin-nested-loading {
  height: calc(100% - 80px);
}
.list-event .ant-spin-container {
  height: 100%;
}
.list-event .disabled-icon {
  cursor: not-allowed;
}
.list-event .ant-select-selector {
  height: 39px !important;
  padding-right: 70px !important;
}
.list-event .ant-select-selector .ant-select-selection-overflow {
  flex-wrap: nowrap;
  height: 39px !important;
  align-items: center;
  transform: translateY(-7px) !important;
}
.list-event .from-to-date .ant-row {
  margin: 0 !important;
}
.list-event .from-to-date .ant-row .ant-col:first-child {
  padding-left: 0 !important;
}
.list-event .from-to-date .ant-row .ant-col:last-child {
  padding-right: 0 !important;
}
.list-event .from-to-date .ant-row .ant-picker {
  border-radius: 4px !important;
  height: 34px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-style: none;
}
.list-event .from-to-date .ant-row .ant-form-item-control-input {
  min-height: unset !important;
}
.list-event .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 0px;
  top: 0px;
  right: 30px;
}
.list-event ::-webkit-scrollbar:horizontal {
  height: 3px;
}
.list-event .ant-image {
  width: 100% !important;
}
.list-event .ant-image-mask {
  border-radius: 0.5rem;
}
.ant-image-preview-img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.popup-view-more .ant-popover-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 233, 233, var(--tw-bg-opacity));
  border-radius: 8px;
  padding: 0px;
}
.popup-view-more .ant-popover-arrow-content {
  background-color: #e9e9e9 !important;
}
</style>
