import store from '@/vuex/store';

const guard = (from) => {
  if (store.getters.allMenu.includes(from.path)) {
    return true;
  }
  return { path: '404' };
};

const adminRoutes = [
  {
    path: '',
    name: 'dashboard',
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'
      ),
    children: [
      {
        path: '',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard'),
      },
    ],
  },
  {
    path: 'io-management',
    name: 'io-management',
    children: [
      {
        path: '/io-management/object-management',
        name: '/io-management/object-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/object-information/list.vue'
          ),
      },
      {
        path: '/io-management/object-management/create',
        name: '/io-management/object-management/create',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/object-information/create.vue'
          ),
      },
      {
        path: '/io-management/object-management/edit',
        name: '/io-management/object-management/edit',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/object-information/edit.vue'
          ),
      },
      {
        path: 'group-management',
        name: 'group-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Identity Management" */ '@/view/io-information/group-management/list.vue'
          ),
      },
      {
        path: '/io-management/group-management/search',
        name: '/io-management/group-management/search',
        component: () =>
          import(
            '@/view/io-information/group-management/components/Search.vue'
          ),
      },
      {
        path: '/io-management/event-verify-management',
        name: '/io-management/event-verify-management',
        beforeEnter: [guard],
        component: () =>
            import(
                '@/view/event-verify-management/list.vue'
                ),
      },
    ],
  },
  {
    path: 'alert-system',
    name: 'alert-system',
    children: [
      {
        path: '/alert-system/alert-configuration',
        name: '/alert-system/alert-configuration',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/alert-system/alert-configuration/AlertConfiguration.vue'
          ),
      },
      {
        path: '/alert-system/predict-management',
        name: '/alert-system/predict-management',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/alert-system/predict-management/PredictManagement.vue'
          ),
      },
      {
        path: '/alert-system/alert-coordinator',
        name: '/alert-system/alert-coordinator',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/alert-system/alert-coordinator/AlertCoordinator.vue'
          ),
      },
      {
        path: 'list-alert',
        name: 'list-alert',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/alert-system/list-alert/list.vue'
          ),
      },
    ],
  },
  {
    path: 'settings',
    name: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'
      ),
    children: [
      // {
      //   path: 'profile-settings',
      //   name: 'profile-settings',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
      //     ),
      // },
      {
        path: 'profile-settings/profile',
        name: 'set-profile',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      // {
      //   path: 'profile-settings/account',
      //   name: 'set-account',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Account.vue'
      //     ),
      // },
      {
        path: 'profile-settings/password',
        name: 'set-password',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Password.vue'
          ),
      },
    ],
  },
  {
    path: 'manager',
    name: 'user-management',
    children: [
      {
        path: 'user-group',
        name: 'user-group-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/user-group/UserGroup.vue'
          ),
      },
    ],
  },
  {
    path: 'identity',
    name: 'identity-management',
    children: [
      {
        path: 'object',
        name: 'object-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Identity Management" */ '@/view/object-information/list.vue'
          ),
      },
      {
        path: 'object/create',
        name: 'create-object',
        component: () =>
          import(
            /* webpackChunkName: "Identity Management" */ '@/view/identity-management/object-management/create.vue'
          ),
      },
      {
        path: 'object/edit',
        name: 'edit-object',
        component: () =>
          import(
            /* webpackChunkName: "Identity Management" */ '@/view/identity-management/object-management/edit.vue'
          ),
      },
      {
        path: 'io-group',
        name: 'io-group-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Identity Management" */ '@/view/identity-management/io-group-management/groups.vue'
          ),
      },
    ],
  },
  {
    path: 'system',
    name: 'system',
    children: [
      {
        path: 'menu',
        name: 'menu-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/menu/Menu.vue'
          ),
      },
      {
        path: 'user',
        name: 'user-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/user/User.vue'
          ),
      },
      {
        path: 'role',
        name: 'role-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/role/Role.vue'
          ),
      },
      {
        path: 'role-io-group',
        name: 'role-io-group-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/role-io-group/list.vue'
          ),
      },
      {
        path: 'log-management',
        name: 'log-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/coordination-system/log-management/list.vue'
          ),
      },
      {
        path: 'list-camera',
        name: 'list-camera',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/camera/Camera.vue'
          ),
      },
    ],
  },
  {
    path: 'coordination-system',
    name: 'coordination-system',
    children: [
      {
        path: 'strategic-coordination',
        name: 'strategic-coordination',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "strategic-coordination" */ '@/view/strategic-coordination/list.vue'
          ),
      },
      {
        path: 'device-management',
        name: 'device-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/coordination-system/device-management/list.vue'
          ),
      },
      {
        path: 'channel',
        name: 'channel',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/channel-management/list.vue'
          ),
      },
    ],
  },
  {
    path: '/trace-management',
    name: '/trace-management',
    children: [
      {
        path: '/trace-management/identification-event',
        name: '/trace-management/identification-event',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/trace-management/identification-event/list.vue'
          ),
      },
      {
        path: '/trace-management/identification-event/detail',
        name: '/trace-management/identification-event/detail',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/trace-management/identification-event/detail.vue'
          ),
      },
      {
        path: '/trace-management/identification-event/detail-socket',
        name: '/trace-management/identification-event/detail-socket',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/trace-management/identification-event-socket/detail.vue'
          ),
      },
      {
        path: '/trace-management/object-tracing',
        name: '/trace-management/object-tracing',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/trace-management/object-tracing/list.vue'
          ),
      },
      {
        path: '/trace-management/object-tracing/detail',
        name: '/trace-management/object-tracing/detail',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/trace-management/object-tracing/detail.vue'
          ),
      },
      {
        path: '/trace-management/intrusion-event',
        name: '/trace-management/intrusion-event',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/trace-management/intrusion-event/detail.vue'
          ),
      },
      {
        path: '/trace-management/in-out-event',
        name: '/trace-management/in-out-event',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/trace-management/in-out-event/index.vue'
          ),
      },
      {
        path: '/trace-management/object-tracing-socket',
        name: '/trace-management/object-tracing-socket',
        component: () =>
            import(
                /* webpackChunkName: "Manager" */ '@/view/trace-management/object-tracing-socket/index.vue'
                ),
      },
    ],
  },
  {
    path: 'tracing-socket-demo',
    name: 'tracing-socket-demo',
    beforeEnter: [guard],
    component: () =>
        import(
            /* webpackChunkName: "Manager" */ '@/view/trace-management/object-tracing-socket/index.vue'
            ),
  },
  {
    path: 'report-statistical',
    name: 'report-statistical',
    children: [
      {
        path: '/report-statistical/synthesis-report',
        name: '/report-statistical/synthesis-report',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/report-statistical/index.vue'
          ),
      },
    ],
  },
];

export default adminRoutes;
