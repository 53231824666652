<template>
  <div class="bg-ems-gray1000 h-full">
    <cds-main class="h-full flex flex-col">
      <cds-cards :title="null" class="m-0">
        <div
          class="pl-[16px] pr-[20px] py-[18px] grid grid-cols-12 justify-between items-center bg-ems-gray900"
        >
          <div class="col-span-2">
            <input-search
              class="w-full ant-input-sm"
              v-model:value="formState.name"
              :maxlength="50"
              size="small"
              :placeholder="$t('common.search')"
              :has-search-advanced="false"
              @press-enter="onSearch"
            >
            </input-search>
          </div>
          <cds-search-form-button
            class="!pb-0 col-span-10 justify-end"
            :buttons="buttonsForm"
            @onClear="refresh"
            @onCreate="onCreate"
            @onDelete="onDeleteMulti"
          />
        </div>
      </cds-cards>
      <cds-cards title="" class="h-full full-height-card m-0">
        <cds-table
          ref="table"
          :has-checkbox="true"
          :index-column="true"
          pagination
          :columns="columns"
          :api-fetch="apiFetch"
          :param-fetch="formState"
        >
          <template #custom-body="{ column, record }">
            <template v-if="column.key === 'action' && record">
              <action-button
                :buttons="buttonActions"
                @onView="onView(record)"
                @onEdit="onEdit(record)"
                @onDelete="onDelete(record)"
              />
            </template>
            <template v-if="column.key === 'name' && record">
              <p
                class="text-ems-main4 hover:underline cursor-pointer"
                @click="
                  hasPermEdit(perms, 'list-alert')
                    ? onEdit(record)
                    : onView(record)
                "
              >
                {{ record.name }}
              </p>
            </template>
            <template v-if="column.key === 'status' && record">
              <div v-if="record.status" class="w-full">
                <div
                  class="bg-ems-boTro4_600 w-[fit-content] mx-auto px-10px py-3px rounded-4px"
                >
                  {{ t('list_alert.active') }}
                </div>
              </div>
              <div v-else class="w-full">
                <div
                  class="bg-ems-main1 w-[fit-content] mx-auto px-10px py-3px rounded-4px"
                >
                  {{ t('list_alert.inactive') }}
                </div>
              </div>
            </template>
          </template>
        </cds-table>
      </cds-cards>
    </cds-main>
  </div>
  <cds-modal
    :title="t('predict_management.detail_alert')"
    :visible="isShowModal"
    :mask-closable="false"
    :width="'1095px'"
    hide-footer
    @onCancel="isShowModal = false"
  >
    <form-modal ref="modal" :detail="formDetail" />
  </cds-modal>

  <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
</template>
<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import ConstantAPI from '@/config/ConstantAPI';
import { useStore } from 'vuex';
import InputSearch from '@/components/input-search/index.vue';
import { useI18n } from 'vue-i18n';
import { defaultButton, FORM_MODE } from '@/util/common-constant';
import ActionButton from '../../../components/action-button/index.vue';
import FormModal from '../../alert-system/predict-management/FormModal.vue';
//import ClipButton from '@/components/buttons/ClipButton.vue';
import { useRouter } from 'vue-router';
import { formatDate, hasPermEdit } from '@/util/common-utils';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { notification } from 'ant-design-vue';

const apiFetch = ConstantAPI.ALERT.GET_BY_CONDITION;
const showConfirmCustom = ref(null);
const { t } = useI18n();
const { buttonsForm, buttonActions } = defaultButton('list-alert');
const table = ref(null);
const columns = ref([
  {
    title: t('list_alert.name'),
    dataIndex: 'name',
    key: 'name',
    width: 310,
    align: 'left',
  },
  {
    title: t('list_alert.waring_method'),
    dataIndex: 'liveCamera',
    key: 'liveCamera',
    width: 200,
    align: 'left',
    customRender({ record }) {
      return handleWarningMethod(record);
    },
  },
  {
    title: t('list_alert.alarm_sending_channel'),
    dataIndex: 'sendToEmail',
    key: 'sendToEmail',
    width: 250,
    align: 'left',
    customRender({ record }) {
      return handleWarningChannel(record);
    },
  },
  {
    title: t('list_alert.created_date'),
    dataIndex: 'createdDate',
    key: 'createdDate',
    width: 200,
    align: 'center',
    customRender({ record }) {
      return formatDate(record.createdDate, 'DD/MM/YYYY');
    },
  },
  {
    title: t('list_alert.updated_date'),
    dataIndex: 'modifiedDate',
    key: 'modifiedDate',
    width: 200,
    align: 'center',
    customRender({ record }) {
      return formatDate(record.modifiedDate, 'DD/MM/YYYY');
    },
  },
  {
    title: t('list_alert.status'),
    dataIndex: 'status',
    key: 'status',
    width: 200,
    align: 'center',
  },
  {
    title: t('list_alert.action'),
    key: 'action',
    fixed: 'right',
    width: 200,
    align: 'center',
  },
]);
const { state, dispatch } = useStore();
const perms = computed(() => state.auth.userInfo.permissions);
onMounted(() => {
  dispatch('setTitleHeader', t('list_alert.title'));
});
// const permissions = computed(() => state.auth.userInfo.permissions || []);
const isShowModal = ref(false);
let formState = reactive({
  name: null,
  // names: null,
  // types: null,
  // groups: null,
  // image: [],
});

const onSearch = () => {
  table.value.fetchData(true).then();
};

// const handleDeleteRow = async (id) => {
//   let payload = [];
//   payload.push({
//     id,
//   });
//   await dispatch('person/delete', payload);
// };
const onView = (record) => {
  dispatch('alertConfiguration/changeAction', FORM_MODE.VIEW);
  router.push({
    path: '/alert-system/alert-configuration',
    query: { id: record.id, mode: FORM_MODE.VIEW },
  });
};

const resetForm = () => {
  formState.name = '';
};

const router = useRouter();
const refresh = () => {
  resetForm();
  table.value.resetAndFetch(true);
  router.push({
    query: {},
  });
};
const onCreate = () => {
  dispatch('alertConfiguration/changeAction', FORM_MODE.CREATE);
  router.push({
    path: '/alert-system/alert-configuration',
    query: { mode: FORM_MODE.CREATE },
  });
};

const onEdit = (record) => {
  dispatch('alertConfiguration/changeAction', FORM_MODE.UPDATE);
  router.push({
    path: '/alert-system/alert-configuration',
    query: { id: record.id, mode: FORM_MODE.UPDATE },
  });
};

const onDelete = async (record) => {
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    dispatch('alertConfiguration/delete', [record]).then(() => {
      table.value.fetchData(true);
    });
  }
};

const handleWarningChannel = (record) => {
  if (record) {
    const method = [];
    if (record.sendToEmail) {
      method.push('Email');
    }
    if (record.sendToMobile) {
      method.push('Mobile');
    }
    if (record.sendToEms) {
      method.push('EMS');
    }
    if (record.sendToTele) {
      method.push('Tele');
    }
    if (record.sendToVms) {
      method.push('VMS');
    }
    return method.toString();
  }
  return '';
};

const handleWarningMethod = (record) => {
  if (record) {
    const method = [];
    if (record.popup) {
      method.push('Popup');
    }
    if (record.warningByAlarmSound) {
      method.push(t('alert_configuration.alarm_sound'));
    }
    if (record.warningBySound) {
      method.push(t('alert_configuration.sound'));
    }
    if (record.liveCamera) {
      method.push('Camera');
    }
    return method.toString();
  }
  return '';
};
const onDeleteMulti = async () => {
  const listId = table.value.selectedRows.map((item) => item.id);
  if (listId.length < 1) {
    notification.error({
      message: t('common.notification'),
      description: t('common.no_record_selected'),
      duration: 4,
    });
    return;
  }
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    dispatch('alertConfiguration/delete', table.value.selectedRows).then(() => {
      setTimeout(() => {
        table.value.fetchData(true);
      }, 1000);
    });
  }
};
</script>
