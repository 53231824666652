<template>
  <cds-main class="dashboard-in-out">
    <a-spin :spinning="loading">
      <div
        class="flex justify-between items-center h-[76px] bg-ems-gray800 p-4 gap-3"
      >
        <div class="flex gap-20px">
          <cds-select
            class="!w-[205px] h-[39px]"
            :placeholder="$t('in_out_event.group_camera')"
            :options="listGroupCamera"
            v-model:value="formState.groupCameraId"
            :allow-clear="true"
            :show-search="true"
            label-prop="name"
            value-prop="id"
            @change="onChangeGroupCam"
          />
          <div class="relative h-[39px]">
            <cds-select
              class="!w-[205px] h-[39px]"
              :placeholder="t('object_information.camera')"
              :options="listCameraByGroup"
              v-model:value="formState.cameraIds"
              :allow-clear="true"
              label-prop="name"
              value-prop="id"
              multiple
              :max-tag-count="1"
              :max-tag-text-length="8"
              :show-search="true"
              @change="onSearch"
            >
            </cds-select>
            <cds-feather-icons
              v-if="formState.cameraIds.length === 0"
              type="chevron-down"
              class="text-ems-gray400 absolute right-2 inline-flex items-center h-full h-[34px]"
            />
          </div>
          <cds-select
            class="!w-[205px] h-[39px]"
            :placeholder="$t('in_out_event.statistics_by')"
            :options="TIME_SELECT"
            v-model:value="formState.chartBy"
            :allow-clear="true"
            :show-search="true"
            @change="onChangeChartBy"
          />
          <DatePickerChart
            v-model:value="formState.dateFrom"
            :placeholder="$t('in_out_event.from')"
            :picker="typeDatePicker"
            :format="formatDatePicker"
            class="!w-[205px] h-[39px]"
            :disabled-date="disabledFromDate"
            :show-time="true"
            @pickDate="onSearch"
          />
          <DatePickerChart
            v-model:value="formState.dateTo"
            :placeholder="$t('in_out_event.to')"
            :picker="typeDatePicker"
            :format="formatDatePicker"
            :is-from-date="false"
            class="!w-[205px] h-[39px]"
            :disabled-date="disabledToDate"
            :show-time="true"
            @pickDate="onSearch"
          />
        </div>
        <div class="flex items-center gap-[20px]">
          <clip-button
            class="flex items-center text-sm text-ems-white text-nowrap h-[39px] !px-[30px]"
            :type="'secondary'"
            @click="resetForm"
          >
            <cds-feather-icons
              type="rotate-cw"
              size="16"
              class="!text-ems-white mr-2"
            />
            {{ $t('common.reset') }}
          </clip-button>
        </div>
      </div>

      <div
        class="grid grid-cols-4 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-3 p-4"
      >
        <div class="min-h-[415px] bg-ems-gray800 p-3 text-ems-gray200">
          <div v-if="dataVehicle">
            <p class="text-white font-semibold uppercase">
              {{ $t('in_out_event.vehicle_statistics') }}
            </p>
            <p class="text-ems-main2 font-semibold text-3xl">
              {{ formatNumberReZero(dataVehicle.total) }}
            </p>
            <div class="flex items-center gap-2 mt-2">
              <img src="@/static/img/icon/go-in.svg" alt="" />
              <span>{{ $t('in_out_event.number_of_vehicles_in') }}:</span>
              <span class="text-ems-main2 text-2xl font-bold">
                {{
                  formatNumberReZero(
                    totalInOut(IN_OUT_STATUS_TEXT.ZONE_FLOW_IN)
                  )
                }}
              </span>
            </div>
            <div class="flex items-center gap-2 mt-2">
              <img src="@/static/img/icon/go-out.svg" alt="" />
              <span>{{ $t('in_out_event.number_of_vehicles_out') }}:</span>
              <span class="text-ems-main2 text-2xl font-bold">
                {{
                  formatNumberReZero(
                    totalInOut(IN_OUT_STATUS_TEXT.ZONE_FLOW_OUT)
                  )
                }}
              </span>
            </div>
            <div
              class="w-full px-4 border-b-[1px] border-ems-gray600 my-3"
            ></div>
            <p class="mb-1 font-semibold">{{ $t('in_out_event.detail') }}</p>
            <div class="grid grid-cols-4">
              <span class="font-semibold col-span-2">{{
                $t('in_out_event.categorization')
              }}</span>
              <span class="font-semibold">{{ $t('in_out_event.go_in') }}</span>
              <span class="flex justify-end font-semibold">{{
                $t('in_out_event.go_out')
              }}</span>
            </div>
            <div class="w-full px-4 border-b-[1px] border-ems-gray600"></div>
            <div v-for="(e, i) in dataVehicle.vehicleNameAgg" :key="i">
              <div class="grid grid-cols-4">
                <span class="col-span-2">{{ keyValVehicle[e.key] }}</span>
                <span class="text-ems-main2">{{
                  formatNumberReZero(e.countIn)
                }}</span>
                <span class="flex justify-end text-ems-main2">{{
                  formatNumberReZero(e.countOut)
                }}</span>
              </div>
              <div class="w-full px-2 border-b-[1px] border-ems-gray600"></div>
            </div>
          </div>
        </div>
        <div class="min-h-[415px] bg-ems-gray800 w-full p-3 text-ems-gray200">
          <div v-if="dataHuman">
            <p class="text-white font-semibold uppercase">
              {{ $t('in_out_event.human_statistics') }}
            </p>
            <p class="text-ems-main2 font-semibold text-3xl">
              {{ formatNumberReZero(dataHuman.total) }}
            </p>
            <div class="flex items-center gap-2 mt-2">
              <img src="@/static/img/icon/go-in.svg" alt="" />
              <span>{{ $t('in_out_event.number_of_human_in') }}:</span>
              <span class="text-ems-main2 text-2xl font-bold">{{
                formatNumberReZero(dataHuman.statistic.ZONE_FLOW_IN.total)
              }}</span>
            </div>
            <div class="flex items-center gap-2 mt-2">
              <img src="@/static/img/icon/go-out.svg" alt="" />
              <span>{{ $t('in_out_event.number_of_human_out') }}:</span>
              <span class="text-ems-main2 text-2xl font-bold">{{
                formatNumberReZero(dataHuman.statistic.ZONE_FLOW_OUT.total)
              }}</span>
            </div>
            <div
              class="w-full px-4 border-b-[1px] border-ems-gray600 my-3"
            ></div>
            <p class="mb-1 font-semibold">{{ $t('in_out_event.detail') }}</p>
            <div class="grid grid-cols-3">
              <span class="font-semibold">{{ $t('in_out_event.sex') }}</span>
              <span class="flex justify-center font-semibold">{{
                $t('in_out_event.go_in')
              }}</span>
              <span class="flex justify-end font-semibold">{{
                $t('in_out_event.go_out')
              }}</span>
            </div>
            <div
              class="w-full px-4 border-b-[1px] border-ems-gray600 my-2"
            ></div>
            <div class="grid grid-cols-3">
              <span>{{ $t('in_out_event.male') }}</span>
              <span class="pl-[35px] text-ems-main2">
                {{ formatNumberReZero(dataHuman.statistic.ZONE_FLOW_IN.male) }}
              </span>
              <span class="flex justify-end text-ems-main2">
                {{ formatNumberReZero(dataHuman.statistic.ZONE_FLOW_OUT.male) }}
              </span>
            </div>
            <div
              class="w-full px-4 border-b-[1px] border-ems-gray600 my-3"
            ></div>
            <div class="grid grid-cols-3">
              <span>{{ $t('in_out_event.female') }}</span>
              <span class="pl-[35px] text-ems-main2">
                {{
                  formatNumberReZero(dataHuman.statistic.ZONE_FLOW_IN.female)
                }}
              </span>
              <span class="flex justify-end text-ems-main2">
                {{
                  formatNumberReZero(dataHuman.statistic.ZONE_FLOW_OUT.female)
                }}
              </span>
            </div>
            <div
              class="w-full px-4 border-b-[1px] border-ems-gray600 my-3"
            ></div>
            <div class="grid grid-cols-3">
              <span>{{ $t('in_out_event.other') }}</span>
              <span class="pl-[35px] text-ems-main2">
                {{
                  formatNumberReZero(dataHuman.statistic.ZONE_FLOW_IN.unknown)
                }}
              </span>
              <span class="flex justify-end text-ems-main2">
                {{
                  formatNumberReZero(dataHuman.statistic.ZONE_FLOW_OUT.unknown)
                }}
              </span>
            </div>
            <div
              class="w-full px-4 border-b-[1px] border-ems-gray600 my-3"
            ></div>
          </div>
        </div>
        <div class="col-span-2 min-h-[415px] bg-ems-gray800 text-ems-gray200">
          <!-- Header -->
          <div
            class="flex justify-between items-center p-20px border-b border-b-1 border-b-solid border-b-ems-gray700"
          >
            <h3 class="uppercase text-white font-semibold">
              {{ $t('in_out_event.summary_chart') }}
            </h3>
          </div>
          <!-- Content -->
          <div>
            <Echarts
              ref="purposeStatistics"
              :option="chartData"
              class-name="chart bg-evisa-gray50 rounded-lg pr-4"
              width="100%"
              height="320px"
            ></Echarts>
            <!--          <cds-loading v-else class="h-260px" />-->
          </div>
          <!-- Footer -->
          <div
            class="flex justify-start gap-10 items-center p-20px border-t border-t-1 border-t-solid border-t-ems-gray700"
          >
            <div class="flex gap-2.5 items-center">
              <div class="w-12px h-12px rounded-full bg-ems-main1"></div>
              <span class="text-ems-gray400">{{ $t('in_out_event.in') }}</span>
            </div>
            <div class="flex gap-2.5 items-center">
              <div class="w-12px h-12px rounded-full bg-ems-boTro3_600"></div>
              <span class="text-ems-gray400">{{ $t('in_out_event.out') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 px-4 pb-4 3xl:h-[calc(100%-624px)]">
        <InOutTable
          v-model:params="formState"
          :refresh="refresh"
          @on-search="onSearch"
        />
      </div>
    </a-spin>
  </cds-main>
</template>
<script setup>
import { computed, onMounted, reactive, ref, watch, onUnmounted } from 'vue';
import Echarts from '@/components/e-charts/index.vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { STATISTICAL_OBJECT, TIME_SELECT } from '@/util/common-constant';
import DatePickerChart from '@/components/date-picker-chart/DatePickerChart.vue';
import { formatNumberReZero, setDefaultDateForm } from '@/util/common-utils';
import InOutTable from './InOutTable.vue';
import { useRoute, useRouter } from 'vue-router';
import { IN_OUT_STATUS_TEXT } from '@/config/Constant';

const { state, dispatch } = useStore();

const listCameraByGroup = computed(() => state.tracingObject.listCameraByGroup);
const listGroupCamera = computed(() => state.tracingObject.listGroupCamera);
const dataVehicle = computed(() => state.inOutEvent.dataVehicle);
const dataHuman = computed(() => state.inOutEvent.dataHuman);
const keyValVehicle = computed(() => state.inOutEvent.keyValVehicle);
const dataChart = computed(() => state.inOutEvent.dataChart);
const loading = computed(() => state.inOutEvent.loading);

const typeDatePicker = ref('month');
const formatDatePicker = ref('MM/YYYY');

const disabledToDate = (current) => {
  return current && formState.dateFrom && current < formState.dateFrom;
};
const disabledFromDate = (current) => {
  return current && formState.dateTo && current > formState.dateTo;
};

const formState = reactive({
  groupCameraId: null,
  cameraIds: [],
  chartBy: STATISTICAL_OBJECT.MONTH,
  dateFrom: null,
  dateTo: null,
});
const refresh = ref(false);

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

onMounted(async () => {
  if (route.query.groupCameraId) {
    await dispatch('tracingObject/getListCameraByGroups', [
      route.query.groupCameraId,
    ]);
  }
  formState.groupCameraId = route.query.groupCameraId
    ? route.query.groupCameraId
    : null;
  formState.cameraIds = route.query.cameraIds ? route.query.cameraIds : [];
  formState.chartBy = route.query.chartBy
    ? route.query.chartBy
    : STATISTICAL_OBJECT.MONTH;
  formState.dateFrom = route.query.dateFrom
    ? new Date(route.query.dateFrom)
    : new Date(new Date().getFullYear(), 0) || null;
  formState.dateTo = route.query.dateTo
    ? new Date(route.query.dateTo)
    : new Date() || null;
  handleChartChange(formState.chartBy);
  dispatch('inOutEvent/getDataChart', formState);
});
const COLOR = ['#5B5B9F', '#DCC51E'];

const onSearch = () => {
  router.push({
    query: {
      ...route.query,
      ...formState,
    },
  });
  dispatch('inOutEvent/getDataChart', formState);
};

const chartData = reactive({
  color: COLOR,
  xAxis: {
    type: 'value',
    axisLabel: {
      textStyle: {
        color: '#F7F0F7',
      },
      formatter: '',
    },
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: '#2B2A3A',
      },
    },
    axisTick: {
      alignWithLabel: true,
      inside: true,
    },
  },
  yAxis: {
    data: [],
    axisLabel: {
      interval: 0,
      fontSize: 15,
      textStyle: {
        color: '#F7F0F7',
      },
    },
    axisLine: {
      lineStyle: {
        type: 'dashed',
        color: '#2B2A3A',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed',
        color: '#2B2A3A',
      },
    },
  },
  series: [],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
  },
  grid: {
    left: '2%',
    right: '0%',
    bottom: '10px',
    top: '10px',
    containLabel: true,
  },
  dataZoom: [
    {
      type: 'slider',
      start: 0,
      end: 100,
      yAxisIndex: 0,
      maxValueSpan: 15,
    },
  ],
});

watch(
  () => dataChart.value,
  (val) => {
    chartData.yAxis = {
      data: val.key,
      axisLabel: {
        interval: 0,
        fontSize: 15,
        textStyle: {
          color: '#F7F0F7',
        },
      },
      axisLine: {
        lineStyle: {
          type: 'dashed',
          color: '#2B2A3A',
        },
      },
    };
    chartData.series = [
      {
        name: t('in_out_event.in'),
        type: 'bar',
        data: val.dataIn,
        barMaxWidth: 24,
      },
      {
        name: t('in_out_event.out'),
        type: 'bar',
        data: val.dataOut,
        barMaxWidth: 24,
      },
    ];
  },
  {
    deep: true,
  }
);

const resetForm = () => {
  formState.groupCameraId = null;
  formState.cameraIds = [];
  formState.chartBy = STATISTICAL_OBJECT.MONTH;
  formState.dateFrom = new Date(new Date().getFullYear(), 0);
  formState.dateTo = new Date();
  typeDatePicker.value = 'month';
  formatDatePicker.value = 'MM/YYYY';
  refresh.value = true;
  onSearch();
};

const totalInOut = (type) => {
  const obj = dataVehicle.value.directionTypeAgg.find(
    (item) => item.key === type
  );
  return obj ? obj.doc_count : 0;
};

const onChangeGroupCam = async (idGroup) => {
  formState.cameraIds = [];
  await dispatch('tracingObject/getListCameraByGroups', [idGroup]);
  onSearch();
};

const onChangeChartBy = (val) => {
  setDefaultDateForm(formState, val);
  handleChartChange(val);
  onSearch();
};

const handleChartChange = (chartBy) => {
  switch (chartBy) {
    case STATISTICAL_OBJECT.YEAR:
      typeDatePicker.value = 'year';
      formatDatePicker.value = 'YYYY';
      break;
    case STATISTICAL_OBJECT.WEEK:
      typeDatePicker.value = 'week';
      formatDatePicker.value = null;
      break;
    case STATISTICAL_OBJECT.MONTH:
      typeDatePicker.value = 'month';
      formatDatePicker.value = 'MM/YYYY';
      break;
    case STATISTICAL_OBJECT.QUARTER:
      typeDatePicker.value = 'quarter';
      formatDatePicker.value = null;
      break;
    default:
      typeDatePicker.value = 'date';
      formatDatePicker.value = 'DD/MM/YYYY HH:mm:ss';
  }
};
</script>

<style lang="scss">
.dashboard-in-out .ant-select-selector {
  border-color: #444459 !important;
  height: 39px !important;
  border-radius: 4px !important;
  overflow-x: auto !important;
}
@apply bg-ems-gray700
      .ant-select-selection-search-input {
  .dashboard-in-out .ant-select-selector .ant-select-selection-search {
    height: 39px !important;
    line-height: 39px !important;
  }
}
.dashboard-in-out .ant-select-selector .ant-select-selection-item {
  line-height: 39px !important;
}
.dashboard-in-out .ant-select-selector .ant-select-selection-placeholder {
  line-height: 34px !important;
}
.dashboard-in-out .ant-select-selector .ant-select-selection-overflow {
  flex-wrap: nowrap;
  height: 39px !important;
}
.dashboard-in-out .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
  padding-top: 6px;
}
.dashboard-in-out .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
  border-radius: 20px;
  border-style: none;
  margin-right: 0.25rem;
}
.dashboard-in-out .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .ant-select-selection-item-content {
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.dashboard-in-out .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .anticon-close {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.dashboard-in-out ::-webkit-scrollbar-thumb, .dashboard-in-out ::-webkit-scrollbar-thumb:horizontal {
  background: #cbd5e1 !important;
  border-radius: 8px !important;
}
.dashboard-in-out ::-webkit-scrollbar-thumb:hover, .dashboard-in-out ::-webkit-scrollbar-thumb:horizontal:hover {
  background: #cbd5e1;
}
.dashboard-in-out ::-webkit-scrollbar:horizontal {
  height: 3px;
}
.dashboard-in-out .ant-select-multiple .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 2px;
  top: 0px;
  right: 0.25rem;
}
.dashboard-in-out .ant-select-multiple .ant-select-selection-placeholder {
  left: 17px;
}
.dashboard-in-out .ant-picker {
  border-color: #444459 !important;
  border-radius: 4px !important;
  height: 39px !important;
  line-height: 39px !important;
  padding-left: 17px !important;
}
</style>
