<template>
  <div
    v-if="value"
    class="absolute h-full flex items-center right-16px z-20 cursor-pointer"
  >
    <img
      src="@/static/img/icon/CloseIconGray.svg"
      alt="Icon Upload"
      class="w-18px h-18px"
      @click="onClearImage"
    />
  </div>
  <div
    v-if="isLoading"
    class="absolute w-full h-full top-0 z-20 rounded-4px flex justify-center items-center bg-ems-gray700 opacity-70"
  >
    <a-spin />
  </div>
  <label
    :htmlFor="`basic_${name}`"
    class="h-full flex items-center rounded-full relative cursor-pointer rounded-[4px]"
    :class="{ 'w-full justify-between': !value, 'justify-end': value }"
    @drop="dropHandler($event)"
    @dragover="$event.preventDefault()"
    @dragenter="$event.preventDefault()"
  >
    <div class="relative bg-ems-gray700 w-full h-full rounded-[4px]">
      <div
        v-if="!value"
        class="w-full h-full px-14px flex justify-center items-center gap-12px bg-ems-main2 rounded-4px cursor-pointer"
      >
        <img
          src="@/static/img/icon/ImageIconWhite.svg"
          alt="Icon Upload"
          class="w-20px h-20px"
        />
        <div v-if="!noName" class="text-ems-white text-14px">
          {{ t('identity_object.form_label.search_by_image') }}
        </div>
      </div>
      <a-popover
        v-else
        placement="leftTop"
        :arrow="false"
        overlay-class-name="popover-search-image"
      >
        <template #content>
          <div v-if="value">
            <img
              :src="value"
              :alt="value"
              class="max-h-[210px] max-w-[355px] w-full h-full flex items-center rounded-[4px] border-[1px] border-ems-gray500"
            />
          </div>
        </template>
        <div
          class="w-full h-full px-[16px] flex justify-center items-center gap-[5px] border-[1px] border-ems-boTro3_600 bg-ems-gray900 rounded-[4px]"
        >
          <img
            src="@/static/img/icon/ImageIconYellow.svg"
            alt="Icon Upload"
            class="w-[20px] h-[20px]"
          />
          <div
            class="text-ems-white text-[14px] max-w-[10px] 2xl:max-w-[40px] truncate leading-[22px]"
            :title="fileName ? fileName : value"
          >
            {{ fileName ? fileName : value }}
          </div>
          <div class="w-[18px] invisible"></div>
        </div>
      </a-popover>
      <input
        :id="`basic_${name}`"
        type="file"
        class="absolute invisible file-search-upload"
        accept="image/png, image/jpg, image/jpeg"
        @change="handleUploadImage"
      />
    </div>
  </label>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue';
import { notification } from 'ant-design-vue';
// import ConstantAPI from "@/config/ConstantAPI";
// import {DataService} from "@/dataService/dataService";
import VueTypes from 'vue-types';
import { DataService } from '../../dataService/dataService';
import ConstantAPI from '../../config/ConstantAPI';
import { checkResponse } from '../../util/common-utils';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  isVideo: false,
  value: {
    type: String,
    required: true,
  },
  accept: VueTypes.string,
  name: {
    type: String,
    default: 'image',
  },
  noName: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([]);
const isLoading = ref(false);

const handleUploadImage = async (e) => {
  e.preventDefault();
  const file = e.target.files[0];
  await updateFileValue(file);
};
const dropHandler = (e) => {
  updateFileValue(e.dataTransfer.files[0]);
  e.preventDefault();
};
const onClearImage = () => {
  document.getElementsByClassName(`file-search-upload`)[0].value = null;
  emit('update:value', null);
  emit('change');
  emit('onClear');
};
const fileName = ref('');
const updateFileValue = async (file) => {
  const fileSizeMB = file.size / (1024 * 1024);
  fileName.value = file.name;
  if (fileSizeMB > 2) {
    notification.error({
      message: t('common.notification'),
      description: t('common.Image exceeds'),
      duration: 4,
    });
    return;
  }

  const fmData = new FormData();
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  fmData.append('file', file);
  emit('update:value', null);
  isLoading.value = true;
  const response = await DataService.upload(
    ConstantAPI.upload_service.UPLOAD,
    fmData,
    config
  );
  checkResponse(
    response,
    () => {
      emit('update:value', response.data.url);
      emit('change', file);
      isLoading.value = false;
    },
    () => {
      isLoading.value = false;
      notification.error({
        message: t('common.notification'),
        description: response.error,
        duration: 4,
      });
      emit('update:value', null);
    }
  );
};
</script>

<style>

.popover-search-image {
  z-index: 1100;
  .ant-popover-inner {
    padding: 0 !important;
    border-radius: 4px;
    position: relative;
  }
}

</style>
