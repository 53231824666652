<template>
  <cds-main class="role-io-group flex flex-col h-full">
    <div
      class="pl-[16px] pr-[20px] py-[18px] grid grid-cols-12 justify-between items-center"
    >
      <div class="col-span-2">
        <InputSearch
          class="ant-input-sm w-[324px]"
          v-model:value="formState.roleName"
          size="small"
          :maxlength="50"
          :placeholder="$t('common.search')"
          @press-enter="onSearch"
          @onSearchAdvanced="onSearchAdvanced"
        />
      </div>
      <cds-search-form-button
        class="!pb-0 col-span-10 justify-end"
        :buttons="buttonsForm"
        @onCreate="createGroup"
        @onDelete="onDelete"
        @onClear="refresh"
      />
    </div>
    <cds-cards class="h-full full-height-card m-0">
      <cds-table
        ref="table"
        :has-checkbox="true"
        :index-column="true"
        :pagination="true"
        :columns="columns"
        :api-fetch="apiFetch"
        :param-fetch="paramFetch"
        @handleSelectCheckbox="handleSelectCheckbox"
      >
        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'action' && record">
            <ActionButton
              :buttons="buttonActions"
              @onEdit="onEdit(record)"
              @onView="onView(record)"
              class="text-left"
            />
          </template>
          <template v-if="column.key === 'name' && record">
            <p
              class="text-ems-main4 hover:underline cursor-pointer"
              @click="
                hasPermEdit(perms, 'role') ? onEdit(record) : onView(record)
              "
            >
              {{ record.name }}
            </p>
          </template>
          <template v-if="column.key === 'status' && record">
            <tag
              tag-type="colorful"
              :color="record.status === 1 ? '#006400' : '#5B5B9F'"
              :text="
                record.status
                  ? t('role-group.active')
                  : t('role-group.inactive')
              "
            ></tag>
          </template>
        </template>
      </cds-table>
    </cds-cards>
  </cds-main>

  <cds-modal
    :visible="visible"
    :loading="loading"
    :mask-closable="false"
    hide-footer
    @onCancel="visible = false"
    class="bg-ems-gray800 pb-0 !w-[1095px]"
  >
    <form-modal
      ref="modal"
      @saveSuccess="saveSuccess"
      @create-success="onCreateSuccess"
    ></form-modal>
  </cds-modal>
  <cds-modal
    :visible="isPopupDelete"
    :mask-closable="false"
    hide-footer
    @onCancel="isPopupDelete = false"
    class="bg-ems-gray800 pb-0"
  >
    <ModalConfirm
      ref="modal"
      @confirm="handleConfirm"
      @onClose="isPopupDelete = false"
      :title="t('common.confirm_delete')"
    ></ModalConfirm>
  </cds-modal>
</template>

<script setup>
import { onMounted, reactive, ref, toRaw, watch } from 'vue';
import ConstantAPI from '@/config/ConstantAPI';
import { useForm } from 'ant-design-vue/es/form';
import { useStore } from 'vuex';
import { maxlength } from '@/util/validation';
import _ from 'lodash';
import InputSearch from '@/components/input-search/index.vue';
// import ClipButton from '@/components/buttons/ClipButton.vue';
import Tag from '../../../components/tags/Tag';
import { useI18n } from 'vue-i18n';
import ActionButton from '../../../components/action-button';
import FormModal from './components/FormModal.vue';
import { computed } from 'vue';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import { debounce } from 'lodash';
import { notification } from 'ant-design-vue';
import { trim, hasPermEdit } from '@/util/common-utils';
import { defaultButton } from '@/util/common-constant';
const { state, dispatch } = useStore();
const apiFetch = ConstantAPI.role.SEARCH;
const { t } = useI18n();
const isPopupDelete = ref(false);
const DEBOUNCE_TIME = 500;
const perms = computed(() => state.auth.userInfo.permissions);
const allMenusEms = computed(() => state.menu.menuEms || []);
const allMenusVms = computed(() => state.menu.menuVms || []);
const allGroups = computed(() => state.roleGroup.treeGroup || []);
const columns = ref([
  {
    title: t('role-group.name-group'),
    dataIndex: 'name',
    key: 'name',
    resizable: true,
    width: 400,
  },
  {
    title: t('role-group.desc-group'),
    dataIndex: 'description',
    resizable: true,
    key: 'description',
    width: 500,
  },
  {
    title: t('role-group.status-group'),
    dataIndex: 'status',
    resizable: true,
    key: 'status',
    width: 320,
  },
  {
    title: t('role-group.action-group'),
    dataIndex: 'action',
    key: 'action',
    width: 200,
  },
]);
const buttonActions = [
  {
    permission: 'role:edit',
    type: 'EDIT',
  },
  {
    type: 'VIEW',
  },
];
const { buttonsForm } = defaultButton('role');
const table = ref(null);
const visible = ref(false);
const isShowDelete = ref(false);
onMounted(() => {
  dispatch('setTitleHeader', t('role-group.header'));
  dispatch('menu/getAll');
  dispatch('menu/getMenuEms');
  dispatch('roleGroup/getTreeGroup');
  dispatch('menu/getMenuVms');
  dispatch('roleGroup/getListUsers', { page: 1, username: '' });
  dispatch('roleGroup/getListTreeCamera');
});
const formState = reactive({
  roleName: '',
});
const rules = ref({
  searchKey: [maxlength(50, 'Role')],
});
const { validate } = useForm(formState, rules);
let paramFetch = _.cloneDeep(toRaw(formState));

const setParamFetch = () => {
  paramFetch.roleName = trim(formState.roleName);
};
const delayedFetchData = debounce(() => {
  table.value.page = 1;
  setParamFetch();
  table.value.fetchData().then();
}, DEBOUNCE_TIME);

watch(
  () => formState.roleName,
  () => {
    delayedFetchData();
  }
);

const handleSelectCheckbox = (record) => {
  if (record.length > 0) {
    isShowDelete.value = true;
  } else {
    isShowDelete.value = false;
  }
};
const createGroup = () => {
  dispatch('roleGroup/preCreate');
  visible.value = true;
};

const onSearch = () => {
  validate().then(
    () => {
      setParamFetch();
      table.value.fetchData().then();
    },
    () => {}
  );
};
const refresh = () => {
  formState.roleName = '';
};
const onEdit = (row) => {
  dispatch('roleGroup/preUpdate', {
    row: row,
    allMenusEms: allMenusEms.value,
    allMenusVms: allMenusVms.value,
    allGroups: allGroups.value,
  }).then();
  dispatch('roleGroup/getListUserInRole', { roleId: row.id, query: '' });
  visible.value = true;
};
const onView = (row) => {
  dispatch('roleGroup/preView', {
    row: row,
    allMenusEms: allMenusEms.value,
    allMenusVms: allMenusVms.value,
    allGroups: allGroups.value,
  }).then();
  dispatch('roleGroup/getListUserInRole', { roleId: row.id, query: '' });
  visible.value = true;
};
const handleConfirm = () => {
  let listIDRole = table.value.selectedRows.map((item) => item.id);
  dispatch('roleGroup/delete', { id: listIDRole.join(',') }).then(() => {
    table.value.fetchData();
    isPopupDelete.value = false;
    isShowDelete.value = false;
  });
};
const onDelete = async () => {
  if (isShowDelete.value) {
    isPopupDelete.value = true;
  } else {
    notification.error({
      message: t('common.notification'),
      description: t('common.no_record_selected'),
      duration: 4,
    });
  }
};

const onCreateSuccess = () => {
  visible.value = false;
  table.value.fetchData();
};
</script>

<style lang="scss">
.role-io-group .ant-tag {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
</style>
